import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import {
  Header,
  HeaderIcons,
  LogoContainer,
  LogoImg,
  HeaderMainContainer,
  IconContainer,
  IconGroup,
  IconImg,
  IconText,
  ClientName,
  WeddingInfo,
  TextInfo
} from "./HeaderStyles";
import Logo from "../../assets/icons/logo_green_header.svg";
import { menuItems, menuVisitItems, reserveMenuItems } from "./HeaderOptions";
import MobileMenuComponent from "./MobileMenuComponent";
import SettingsMenuComponent from "./SettingsMenuComponent";
import { formatNames } from "../../infra/services/utils/BrideGroomNames";
import { formatSpace } from "../../infra/services/utils/SpaceName";
import { formatDate } from "../../infra/services/utils/FormatDate";
import Alert from "../../components/alert/Alert";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";
import { WeddingStatus } from "../../pages/reserve/ReservePage";
import { accessReserve } from "../../infra/services/utils/Helpers";
import { Icon, Tooltip } from "antd";
import { VisitsBlockedType } from "../../pages/agenda/VisitAgendaPage";
import { GetWeddingInfo } from "../../infra/requests/MyWeddingRequests";
import { PendingContractTag } from "../../pages/reserve/ReserveStyles";

const HeaderComponent = ({
  wedding,
  history,
  translate,
  activeLanguage,
  match,
  upgrade,
  user
}) => {
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [weddingDB, setWeddingDB] = useState(wedding);

  useEffect(() => {
    getWedding();
  }, []);

  const getWedding = async () => {
    const resultWedding = await GetWeddingInfo();
    const wedding = resultWedding?.success && resultWedding?.data ? resultWedding.data : wedding;
    setWeddingDB(wedding);
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const closeSettingsMenu = () => {
    setSettingsMenuOpen(false);
  };

  const handleClickMenu = url => {
    if (url === "DISABLED") {
      Alert.new({
        type: "success",
        title: translate("AVAILABLE_SOON_ALERT")
      });
    } else {
      if (url === "SETTINGS_MENU") {
        setSettingsMenuOpen(!settingsMenuOpen);
      } else {
        history.push(url);
      }
    }
  };

  const goToHome = () => {
    return accessReserve(user, wedding)
      ? history.push('/home')
      : history.push('/');
  }

  let reserveMenuItemsFilter = reserveMenuItems;
  if (weddingDB?.status == WeddingStatus.RESERVE){
    reserveMenuItemsFilter = reserveMenuItems.filter(item => item.name !== 'BUDGET');
  }
  
  const menuOptions = accessReserve(user, weddingDB)
    ? reserveMenuItemsFilter
    : weddingDB?.visitsBlocked !== VisitsBlockedType.COMPLETED
      ? menuVisitItems
      : menuItems;

  const reservePhase = weddingDB?.status === WeddingStatus.RESERVE
    || weddingDB?.status === WeddingStatus.RESERVE_SINALIZATION;

  return (
    <Header>
      <LogoContainer>
        <LogoImg src={Logo} onClick={goToHome} />
      </LogoContainer>
      <HeaderMainContainer reservePhase={reservePhase}>
        <ClientName>
          {formatNames(wedding?.bride?.name, wedding?.groom?.name)}
        </ClientName>
        <WeddingInfo>
          {accessReserve(user, wedding)
            ? <React.Fragment>
              <TextInfo>{translate('RESERVE_PLATFORM')}</TextInfo>
            </React.Fragment>
            : <React.Fragment>
              <TextInfo>{formatDate(wedding?.date)}</TextInfo>
              <TextInfo>{formatSpace(wedding?.wedding_place)}</TextInfo>
            </React.Fragment>
          }
          {upgrade &&
            <TextInfo>{upgrade[activeLanguage?.code]}</TextInfo>
          }
        </WeddingInfo>
      </HeaderMainContainer>
      <HeaderIcons reservePhase={reservePhase}>
        {menuOptions
          .filter(f => f?.url !== '/interest-list' || (f?.url === '/interest-list' && accessReserve(user, wedding) && wedding?.status !== WeddingStatus.RESERVE_SINALIZATION))
          .map(item =>
            item.url === "SETTINGS_MENU" ? (
              <IconContainer
                key={item.name}
                id={"SETTINGS_MENU"}
                selected={match.url.indexOf(item.url) !== -1}
                onClick={() => handleClickMenu(item.url)}
                settingsOpen={settingsMenuOpen}
              >
                <OutsideAlerter close={closeSettingsMenu}>
                  <React.Fragment>
                    <IconGroup>
                      <IconImg src={item.img} />
                      <IconText>{translate(item.name)}</IconText>
                    </IconGroup>
                    <SettingsMenuComponent
                      open={settingsMenuOpen}
                      user={user}
                      wedding={wedding}
                    />
                  </React.Fragment>
                </OutsideAlerter>
              </IconContainer>
            ) : (
              <IconContainer
                key={item.name}
                id={null}
                selected={match.url.indexOf(item.url) !== -1}
                onClick={() => handleClickMenu(item.url)}
                settingsOpen={false}
              >
                {item?.tooltip && item?.tooltip !== ''
                  ? <Tooltip title={translate(item?.tooltip)}>
                    <IconGroup>
                      <IconImg src={item.img} />
                      <IconText>{translate(item.name)}</IconText>
                    </IconGroup>
                  </Tooltip>
                  : <IconGroup>
                    <IconImg src={item.img} />
                    <IconText>{translate(item.name)}</IconText>
                  </IconGroup>}
              </IconContainer>
            )
          )}
      </HeaderIcons>
      <OutsideAlerter close={closeMobileMenu}>
        <MobileMenuComponent
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          user={user}
          wedding={wedding}
        />
      </OutsideAlerter>
    </Header>
  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  upgrade: state.upgrade,
  user: state.user,
});

export default connect(mapStateToProps)(withLocalize(withRouter(HeaderComponent)));
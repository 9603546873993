import React from "react";
import {
  CardContainer,
  CardContent,
  Title,
  Number,
  Line,
  Text,
  Icon
} from "./SectionCardStyles";
import LongArrowRight from '../svg/LongArrowRight';
import ProgressBar from '../progressBar/ProgressBar';
import { findNumberQuestionsChapter } from "../../infra/services/utils/PageInfo";

const SectionCard = ({ chapter, number, title, text, onClick, tag, guestsInfo, styleInfo, wedding, translate }) => {
  const { num_questions, num_answers } = findNumberQuestionsChapter(chapter, tag, guestsInfo, styleInfo);  

  const showTrendMainDishesWarning = () => {
    if (
      wedding?.upgrade?.tag === 'TREND' &&
      (chapter?.questions[0]?._id === '5e1e547e6c3074ee1a29e567' || chapter?.questions[0]?._id === '5e1e53826c3074ded429e565')
    ) {
      return true;
    }

    return false;
  }

  return (
    <CardContainer onClick={onClick}>
      <CardContent>
        <Number>{number}</Number>
        <Title>{title}</Title>
        <Line />
        <Text dangerouslySetInnerHTML={{ __html: text }} />
        {showTrendMainDishesWarning() && <React.Fragment>
          <Line />
          <Text dangerouslySetInnerHTML={{ __html: translate('TREND_WARNING_MAIN_DISHES') }} />
        </React.Fragment>}
        <Icon><LongArrowRight /></Icon>
      </CardContent>
      <ProgressBar type="card" num_questions={num_questions} num_answers={num_answers} />
    </CardContainer>
  );
}

export default SectionCard;
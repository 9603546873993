import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, FieldArray, initialize } from "redux-form";
import { connect } from "react-redux";
import { DetailContainer, FormContainer } from "../decoration/PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import {
    findPageByTag,
    findPageByID,
} from "../../infra/services/utils/PageInfo";
import {
    findNextChapter,
    findPrevQuestion,
    findNextQuestion
} from "../../infra/services/utils/PageNavigation";
import StaffWeddingPlannerForm from "./StaffWeddingPlannerForm";
import { AnswerQuestion } from "../../infra/requests/MyWeddingRequests";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { SpinLoading } from "../../styles/BasicStyles";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";
import { correctResponseValues } from "../../components/optionCard/OptionUtils";

const sectionTag = "STAFF";
const chapterId = "67c5799bdaa8547cb6e7db97";
const returnURL = "/staff";

const FormValidations = (values) => {
    //console.log('FORM', values);
    return {};
};

class StaffWeddingPlanner extends Component {
    state = {
        chapter: undefined,
        nextChapter: undefined,
        prevQuestion: undefined,
        nextQuestion: undefined,
        isProcessClosed: undefined,
        ready: false,
        loading: false,
    };

    componentDidMount() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        document.body.style.overflowY = "auto";

        this.updateQuestion();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps?.id) {
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
            document.body.style.overflowY = "auto";

            this.updateQuestion();
        }
    }

    updateQuestion = async () => {
        const { weddingInfo, decorationInfo, dispatch } = this.props;

        const { chapters } = findPageByTag(decorationInfo, sectionTag);
        const chapter = findPageByID(chapters, chapterId);
        const nextChapter = findNextChapter(
            decorationInfo,
            chapter.section,
            chapters,
            chapter._id
        );
        const nextQuestion = findNextQuestion(decorationInfo, chapters, chapter, 0);

        const prevQuestion = findPrevQuestion(decorationInfo, chapters, chapter, 0);
        // const isProcessClosed = processClosed(weddingInfo.date);
        const isProcessClosed = processClosed(weddingInfo.blockDate);

        dispatch(initialize("section_form", {}));

        if (chapter.questions) {
            dispatch(
                initialize("section_form", {
                    questions: chapter.questions,
                })
            );
        }

        this.setState({
            chapter,
            nextChapter,
            prevQuestion,
            nextQuestion,
            isProcessClosed,
            ready: true,
        });
    };

    onSubmit = async (values) => {
        try {
            this.setState({ loading: true });

            const { history } = this.props;
            const { chapter, nextChapter, nextQuestion } = this.state;

            //Find question "Trás Wedding Planner" (67c88aae451b84687ed0cc3d)
            const question1 = values.questions.filter((question) =>
                question._id === "67c88aae451b84687ed0cc3d" ? question : null
            )[0];
            if (question1?.answer) question1.answer = correctResponseValues(question1.answer);
            const payload1 = TransformToFormData({
                question: question1._id,
                response: question1.answer?.response,
            });
            const result1 = await AnswerQuestion(payload1);

            if (
                result1.success
            ) {
                //Update answers in the current Chapter
                for (let i = 0; i < chapter.questions.length; i++) {
                    if (chapter.questions[i]._id === question1._id) {
                        chapter.questions[i] = question1;
                    }
                }

                this.setState({
                    chapter,
                });

                history.push(nextQuestion?.url);
            }

            this.setState({ loading: false });
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
        }
    };

    render() {
        const {
            weddingInfo,
            handleSubmit,
            translate,
            activeLanguage,
            history,
            dirty,
            submitting,
        } = this.props;
        const {
            chapter,
            nextChapter,
            prevQuestion,
            nextQuestion,
            isProcessClosed,
            ready,
            loading,
        } = this.state;

        if (!ready) return <SpinLoading />;

        let num_answers = 0;
        for (let i = 0; i < chapter.questions.length; i++) {
            if (
                (chapter.questions[i]._id === "67c88aae451b84687ed0cc3d") &&
                chapter.questions[i].answer?.response?.length > 0
            ) {
                num_answers++;
            }
        }

        return (
            <React.Fragment>
                <LeavePageBlocker
                    when={dirty && !submitting}
                    message={translate("UNSAVED_OPTION_CHANGES")}
                />
                <ProgressBar
                    type="section"
                    num_questions={4}
                    num_answers={num_answers}
                />
                <DecorationNavigation
                    returnUrl={returnURL}
                    mainCategory={chapter.name[activeLanguage?.code]}
                    currentQuestion={chapter.name[activeLanguage?.code]}
                    currentQuestionUrl={`${returnURL}/${chapterId}`}
                    nextQuestion={
                        nextQuestion?.tag
                            ? translate(nextQuestion?.tag)
                            : nextQuestion?.name[activeLanguage?.code]
                    }
                    nextQuestionUrl={nextQuestion?.url}
                    prevQuestion={
                        prevQuestion?.tag
                            ? translate(prevQuestion?.tag)
                            : prevQuestion?.name[activeLanguage?.code]
                    }
                    prevQuestionUrl={prevQuestion?.url}
                />
                <DetailContainer>
                    <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
                        <FieldArray
                            name="questions"
                            component={StaffWeddingPlannerForm}
                            questions={chapter.questions}
                            weddingInfo={weddingInfo}
                            isProcessClosed={isProcessClosed}
                        />
                        {!isProcessClosed && (
                            <SaveButton
                                htmlType="submit"
                                text={translate("SAVE_CONTINUE")}
                                textMobile={translate("SAVE")}
                                withBackground={true}
                                loading={loading}
                            />
                        )}
                    </FormContainer>
                    {isProcessClosed && (
                        <SaveButton
                            htmlType="button"
                            text={translate("CONTINUE")}
                            textMobile={translate("CONTINUE")}
                            withBackground={true}
                            onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
                        />
                    )}
                </DetailContainer>
            </React.Fragment>
        );
    }
}

StaffWeddingPlanner = reduxForm({
    form: "section_form",
    validate: FormValidations,
})(StaffWeddingPlanner);

const mapStateToProps = (state) => ({
    decorationInfo: state.wedding.structure,
    weddingInfo: state.wedding.wedding,
});

export default connect(mapStateToProps)(
    withLocalize(withRouter(StaffWeddingPlanner))
);

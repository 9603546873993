import React from "react";
import { Field } from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  QuestionTitle,
  FieldContainer,
  QuestionDescription
} from '../decoration/PageDetailStyles';
import StaffListQuestion from '../../components/staffListQuestion/StaffListQuestion';

const StaffWeddingPlannerForm = ({ fields, questions, weddingInfo, isProcessClosed, activeLanguage }) => {
  const renderQuestion = (field, question) => {
    return (
      <FieldContainer key={question._id}>
        <QuestionTitle>{question.title[activeLanguage?.code]}</QuestionTitle>
        {
          question.description &&
          <QuestionDescription dangerouslySetInnerHTML={{ __html: question.description[activeLanguage?.code] }} />
        }
        {
          question._id === '67c88aae451b84687ed0cc3d' ?
            <Field
              component={StaffListQuestion}
              name={`${field}.answer.response`}
              question={question}
              isProcessClosed={isProcessClosed}
            />
            : null
        }
      </FieldContainer>
    );
  };

  return (
    <React.Fragment>
      {
        fields.length > 0 && fields.map((question, index) => renderQuestion(question, questions[index]))
      }
    </React.Fragment>
  );
};

export default withLocalize(StaffWeddingPlannerForm);
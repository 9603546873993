import React, { useState } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import {
  CardContainer,
  CardContent,
  BgContainer,
  DetailContainer,
  Title,
  Text,
  PriceQuantityRow,
  Price,
  SelectContainer,
  TextAvailable,
  CheckboxContainer,
  ImageIcon,
  OptionTextContainer,
  ViewFullDesc,
  HideFullDescContainer,
} from "./OptionCardStyles";
import Quantity from "../inputs/Quantity";
import CheckboxInput from "../inputs/CheckboxInput";
import ImageComponent from "../images/ImageComponent";
import Alert from "../alert/Alert";
import { CostTypes, checkOption } from "../../infra/services/utils/Options";
import EnlargeIcon from "../../components/svg/Enlarge";
import ImageModal from "../../components/imageModal/ImageModal";
import truncate from "truncate-html";
import ArrowLeft from "../svg/ArrowLeft";
import RoundButton from "../../components/buttons/RoundButton";
import { isString } from "./OptionUtils";
import { Icon, Tooltip } from "antd";
import styledComponents from "styled-components";
import { PrimaryColour } from "../../styles/Colours";

const textLimit = 40;

const PriceWithWarning = styledComponents.div`
  display: flex;
  align-content: center;
  align-items: center;

  .anticon { 
    color: ${({ premium }) => premium ? PrimaryColour : '#fb635b'};
    margin-right: 5px;
    font-size: ${p => p?.inModal ? '18px' : '20px'} ;
  }
`;

const OptionCard = ({
  question,
  record,
  canEditSection,
  isProcessClosed,
  input,
  wedding,
  translate,
  activeLanguage,
  index,
  inModal = false,
}) => {
  const selected = input?.value && Array.isArray(input.value) && input.value?.find((x) => x.id === record._id);
  const isPremiumOption = wedding && wedding.upgrade && wedding.upgrade.hasPremiumOptions && record && record?.isPremiumOption ? true : false;
  const isTrendOption = wedding && wedding.upgrade && wedding.upgrade.hasPremiumOptions && record && record?.isTrendOption ? true : false;

  const isPremiumOptionOption = record && record?.isPremiumOption ? true : false;
  const isTrendOptionOption = record && record?.isTrendOption ? true : false;

  const showUpgradeIcon = (record && (record?.isTrendFood || record?.isPremiumFood || record?.isTrendOption || record?.isPremiumOption)) ? true : false;

  let disabled = false;
  let disabledForCouple = record?.blockForCouple ? true : false;

  const [showQuantity, setShowQuantity] = useState(selected);
  const [showImageModal, setImageModal] = useState(false);
  const [showFullDesc, setFullDesc] = useState(false);

  let text = record.description ? record.description[activeLanguage?.code] : "";

  if (text?.length > textLimit && !showFullDesc) {
    text = truncate(text, textLimit);
  }

  if (
    question.type === 4 &&
    input.value.length > 0 &&
    !input.value.find((x) => x.id === record._id)
  ) {
    //We have to check every element in input.value
    //If at least one option still exists in this question, then we can disable this option
    //If not then we cannot disable any option as the current option doesn't exist anymore
    for (let i = 0; i < input.value.length; i++) {
      if (checkOption(input.value[i], question, wedding.wedding_place)) {
        disabled = true;
        break;
      }
    }
  }

  const displayQuantity = (value) => {
    setShowQuantity(value);
  };

  const toggleOption = (id) => {
    if (isRoomPlanLocationsQuestion()) {
      return;
    }

    if (!disabled && !disabledForCouple) {
      if (isProcessClosed) {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("PROCESS_CLOSED_TEXT"),
        });
      } else if (canEditSection) {
        const list = [...input.value];
        const index = list.findIndex((x) => x.id === id);

        if (index >= 0) {
          list.splice(index, 1);
          displayQuantity(false);
        } else {
          if (wedding?.upgrade?.hasPremiumOptions && (record?.isPremiumOption || record?.isTrendOption)) {
            if (wedding?.upgrade?.tag === 'PRIME' && record?.isPremiumOption) {
              list.push({ id: id, qtd: 1, cost: 0 });
            } else if (wedding?.upgrade?.tag === 'TREND' && record?.isTrendOption) {
              list.push({ id: id, qtd: 1, cost: 0 });
            } else {
              list.push({ id: id, qtd: 1, cost: record?.cost_price ? record?.cost_price : 0 });
            }
          } else list.push({ id: id, qtd: 1 });
          displayQuantity(true);
        }

        input.onChange(list);
      } else {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("BLOCKED_ANSWER_TEXT"),
        });
      }
    }
  };

  const renderPrice = () => {
    let sufix = "",
      prefix = "";

    if (record.cost_type === CostTypes.QUANTITY) {
      sufix = ` ${translate("QUANTITY_UNI")}`;
    } else if (record.cost_type === CostTypes.TABLE) {
      sufix = ` ${translate("QUANTITY_TABLE")}`;
    } else if (record.cost_type === CostTypes.GUEST) {
      sufix = ` ${translate("QUANTITY_GUEST")}`;
    } else if (record.cost_type === CostTypes.PEOPLE) {
      sufix = ` ${translate("QUANTITY_PEOPLE")}`;
    }

    // const cost = wedding.upgrade && wedding.upgrade.hasPremiumOptions && record.isPremiumOption && record.cost
    //   ? record.costPriceExtra
    //   : record.cost_price;

    let cost = 0;
    if (selected?.cost) {
      cost = isString(selected.cost) ? parseFloat(selected.cost) : selected.cost;
    } else {
      // cost = wedding?.upgrade?.hasPremiumOptions && record?.isPremiumOption
      //   ? record?.costPriceExtra
      //   : record.cost_price;
      cost = record.cost_price;
    }

    return `${prefix}${cost}€${sufix}`;
  };

  const isRoomPlanLocationsQuestion = () => {
    if (question?._id === '67bf2952f973f10df1b6b7fa') {
      canEditSection = false;
      Alert.new({
        type: "error",
        title: translate("ROOMPLAN_BLOCKED_ADD_PLANNER")
      });
      return true;
    }

    return false;
  };

  const openImageModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setImageModal(true);
  };

  const closeImageModal = () => {
    setImageModal(false);
  };

  const openFullDesc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    text = record.description ? record.description[activeLanguage?.code] : "";
    setFullDesc(true);
  };

  const closeFullDesc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    text = record.description ? record.description[activeLanguage?.code] : "";

    if (text?.length > textLimit) {
      text = truncate(text, textLimit);
    }

    setFullDesc(false);
  };

  const showWarningPriceUpdated = (selected && selected.cost !== undefined && record && (isPremiumOption || isTrendOption) && parseFloat(selected.cost) > 0)
    || (selected && selected.cost !== undefined && record && !(isPremiumOption || isTrendOption) && parseFloat(selected.cost) !== record.cost_price)
    ? true : false;

  return (
    <React.Fragment>
      <CardContainer
        type={question.type}
        disabled={disabled || disabledForCouple}
        inModal={inModal}
        onClick={() => toggleOption(record._id)}
      >
        <CardContent selected={selected}>
          <SelectContainer>
            <CheckboxContainer>
              <CheckboxInput
                checkboxType="round"
                meta={{}}
                input={{ value: selected, onChange: () => null }}
              />
            </CheckboxContainer>
            <TextAvailable onlyOne={record?.onlyOneAvailable}>
              {record?.onlyOneAvailable ?
                translate("LIMITED_AVAILABILITY")
                : record.quantity
                  ? translate("VERIFY_AVAILABILITY")
                  : null}
            </TextAvailable>
          </SelectContainer>
          <BgContainer border={false} inModal={inModal}>
            {record.image?.url
              ? <ImageComponent
                zoom
                ratio={0.6}
                url={record.image?.url}
                color={record.image?.color}
                hoverUrl={record?.gallery?.[0]?.url}
                hoverColor={record?.gallery?.[0]?.color}
                onClick={(e) => openImageModal(e)}
              />
              : <ImageComponent
                ratio={0.6}
                url={record.image?.url}
                color={record.image?.color}
                hoverUrl={record?.gallery?.[0]?.url}
                hoverColor={record?.gallery?.[0]?.color}
              />
            }
          </BgContainer>
          <DetailContainer>
            {((showQuantity &&
              record.cost &&
              record.cost_type === CostTypes.QUANTITY) ||
              record.cost) && (
                <PriceQuantityRow inModal={inModal}>
                  {/* {record.cost && <Price>{renderPrice()}</Price>} */}
                  {record.cost
                    ? (showWarningPriceUpdated || showUpgradeIcon)
                      ? <PriceWithWarning inModal={inModal} premium={!showWarningPriceUpdated && isPremiumOption && isTrendOption}>
                        <Tooltip title={showWarningPriceUpdated ? translate('WARNING_COST_UPDATED') : (isPremiumOptionOption && isTrendOptionOption)
                          ? translate('WARNING_OPTION_WEDDING_PRIMEANDTREND')
                          : isPremiumOptionOption
                            ? translate('WARNING_OPTION_WEDDING_PRIME')
                            : isTrendOptionOption
                              ? translate('WARNING_OPTION_WEDDING_TREND')
                              : null}>
                          <Icon type={showWarningPriceUpdated ? 'info-circle' : 'sketch'} />
                        </Tooltip>
                        <Price inModal={inModal}>{renderPrice()}</Price>
                      </PriceWithWarning>
                      : <Price inModal={inModal}>{renderPrice()}</Price>
                    : null
                  }
                  {showQuantity &&
                    record.cost &&
                    record.cost_type === CostTypes.QUANTITY && (
                      <Quantity
                        optionID={record._id}
                        input={input}
                        isProcessClosed={isProcessClosed}
                        canEditSection={canEditSection}
                      />
                    )}
                </PriceQuantityRow>
              )}
            <Title inModal={inModal}>{record.title[activeLanguage?.code]}</Title>
          </DetailContainer>
          {record.description && record.description[activeLanguage?.code] && (
            <OptionTextContainer showFullDesc={showFullDesc} index={index}>
              <Text
                className="optionText"
                selected={selected}
                showFullDesc={showFullDesc}
              >
                {text}
                {record.description[activeLanguage?.code].length > textLimit &&
                  !showFullDesc && (
                    <ViewFullDesc onClick={(e) => openFullDesc(e)}>
                      &nbsp;<span>{translate("VIEW_MORE")}</span>{" "}
                      <span>
                        <ArrowLeft />
                      </span>
                    </ViewFullDesc>
                  )}
                {showFullDesc && (
                  <HideFullDescContainer>
                    <RoundButton
                      text={translate("CLOSE")}
                      onClick={closeFullDesc}
                    />
                  </HideFullDescContainer>
                )}
              </Text>
            </OptionTextContainer>
          )}
        </CardContent>
      </CardContainer>
      {showImageModal && (
        <ImageModal
          title={record.title[activeLanguage?.code]}
          image={record.image?.url}
          color={record.image?.color}
          carousel={record?.gallery?.length > 0 ? true : false}
          gallery={record?.gallery || []}
          handleCancel={closeImageModal}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

export default withLocalize(connect(mapStateToProps)(OptionCard));

import { isArray } from "lodash";

export const findPageByTag = (info, tag) => {
  const key = Object.keys(info).filter(key =>
    info[key].tag === tag ? key : null
  )[0];

  return info[key];
};

export const findPageByID = (info, id) => {
  const key = Object.keys(info).filter(key =>
    info[key]._id === id ? key : null
  )[0];

  return info[key];
};

export const findChapterByID = (chapters, id) => {
  let res;

  for(let i = 0; i < chapters.length; i++) {
    if(chapters[i]._id === id) {
      res = chapters[i];
      break;
    }
  }

  return res;
};

export const findQuestionByID = (chapters, id) => {
  let res;

  for(let i = 0; i < chapters.length; i++) {
    if(chapters[i].questions.length > 0) {
      for(let j = 0; j < chapters[i].questions.length; j++) {
        if(chapters[i].questions[j]._id === id) {
          res = chapters[i].questions[j];
          break;
        }
      }
    }
  }

  return res;
};

//Used for "Our Day" page in the Timeline, not every answer should appear there
export const findAnswerByID = (chapters, food, colors, id) => {
  let res;

  for(let i = 0; i < chapters.length; i++) {
    if(chapters[i].questions.length > 0) {
      for(let j = 0; j < chapters[i].questions.length; j++) {
        if(chapters[i].questions[j].type === 4 || chapters[i].questions[j].type === 5 || chapters[i].questions[j].type === 6) {
          if(chapters[i].questions[j].options.length > 0) {
            for(let h = 0; h < chapters[i].questions[j].options.length; h++) {
              if(chapters[i].questions[j].options[h]._id === id) {
                res = chapters[i].questions[j].options[h];
                break;
              }
            }
          }
        }
        else if(chapters[i].questions[j].type === 11) {
          for(let h = 0; h < colors.length; h++) {
            if(colors[h]._id === id) {
              res = colors[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 12) {
          for(let h = 0; h < food.entrees.length; h++) {
            if(food.entrees[h]._id === id) {
              res = food.entrees[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 13) {
          for(let h = 0; h < food.meat.length; h++) {
            if(food.meat[h]._id === id) {
              res = food.meat[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 14) {
          for(let h = 0; h < food.fish.length; h++) {
            if(food.fish[h]._id === id) {
              res = food.fish[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 15) {
          for(let h = 0; h < food.dessert.length; h++) {
            if(food.dessert[h]._id === id) {
              res = food.dessert[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 16) {
          for(let h = 0; h < food.cake.length; h++) {
            if(food.cake[h]._id === id) {
              res = food.cake[h];
              break;
            }
          }
        }
        else if(chapters[i].questions[j].type === 17) {
          for(let h = 0; h < food.supper.length; h++) {
            if(food.supper[h]._id === id) {
              res = food.supper[h];
              break;
            }
          }
        }
      }
    }
  }

  return res;
};

export const findNumberQuestionsChapters = (chapters, tag, guestsInfo = '', styleInfo = '') => {
  let num_questions = 0, num_answers = 0;

  if(tag === 'STYLE') {
    num_questions = 2;

    if(styleInfo.inspirations?.length > 0) {
      num_answers++;
    }
    if(styleInfo.images?.length > 0) {
      num_answers++;
    }
  }
  else if(tag === 'GUESTS') {
    num_questions = 3;

    if(guestsInfo.provisory_guests) {
      num_answers++;
    }
    if(guestsInfo.guests_location) {
      num_answers++;
    }
    if(guestsInfo.room_plan?._id) {
      num_answers++;
    }
  }
  else if(tag === 'STAFF') {
    if(chapters.length > 0) {
      for(let i = 0; i < chapters.length; i++) {
        if(chapters[i].questions.length > 0) {
          for(let j = 0; j < chapters[i].questions.length; j++) {
            //Only count questions "Animadora Infantil" (5e3184b59621e5d6a3d89744) AND "Funcionários Extra" (5e3185a69621e5d6a3d89749) AND "Utilização do Cofre" (5e6a0a0629196b814faa7ce9) AND "Animação musical" (60e2dbe612cef67e129c0d33)
            if(chapters[i].questions[j]._id === '5e3184b59621e5d6a3d89744' || chapters[i].questions[j]._id === '5e3185a69621e5d6a3d89749' || chapters[i].questions[j]._id === '5e6a0a0629196b814faa7ce9' || chapters[i].questions[j]._id === '60e2dbe612cef67e129c0d33') {
              num_questions++;

              if(chapters[i].questions[j].answer?.response?.length > 0 && chapters[i].questions[j].answer?.response?.id) {
                num_answers++;
              }
            }
          }
        }
      }
    }
  }
  else {
    if(chapters.length > 0) {
      for(let i = 0; i < chapters.length; i++) {
        if(chapters[i].questions.length > 0) {
          for(let j = 0; j < chapters[i].questions.length; j++) {
            //Type 9 is "Information", must be ignored as there is no response needed
            if(chapters[i].questions[j].type !== 9) {
              num_questions++;

              if(chapters[i].questions[j].answer?.response) {
                num_answers++;
              }
            }
          }
        }
      }
    }
  }

  return { num_questions, num_answers };
};

export const findNumberQuestionsChapter = (chapter, tag, guestsInfo = '', styleInfo = '') => {
  let num_questions = 0, num_answers = 0;

  if(tag === 'STYLE') {
    if(chapter._id === '5e42e52b29196b814f617d18') {
      num_questions = 1;

      if(styleInfo.inspirations?.length > 0) {
        num_answers++;
      }
    }
    else if(chapter._id === '5e42e53429196b814f617d30') {
      num_questions = 1;

      if(styleInfo.images?.length > 0) {
        num_answers++;
      }
    }
  }
  else if(tag === 'GUESTS') {
    if(chapter._id === '5e2ada5d47f38787fd9e8423') {
      num_questions = 2;

      if(guestsInfo.provisory_guests) {
        num_answers++;
      }
      if(guestsInfo.guests_location) {
        num_answers++;
      }
    }
    else if(chapter._id === '5e2adaa747f38787fd9e8424') {
      num_questions = 1;

      if(guestsInfo.room_plan?._id) {
        num_answers++;
      }
    }
  }
  else if(tag === 'STAFF') {
    if(chapter.questions.length > 0) {
      for(let i = 0; i < chapter.questions.length; i++) {
        //Only count questions "Animadora Infantil" (5e3184b59621e5d6a3d89744) AND "Funcionários Extra" (5e3185a69621e5d6a3d89749) AND "Utilização do Cofre" (5e6a0a0629196b814faa7ce9) AND "Animação musical" (60e2dbe612cef67e129c0d33)
        if(chapter.questions[i]._id === '5e3184b59621e5d6a3d89744' || chapter.questions[i]._id === '5e3185a69621e5d6a3d89749' || chapter.questions[i]._id === '5e6a0a0629196b814faa7ce9' || chapter.questions[i]._id === '60e2dbe612cef67e129c0d33') {
          num_questions++;

          if(chapter.questions[i].answer?.response?.length > 0 && chapter.questions[i].answer?.response?.id) {
            num_answers++;
          }
        }
      }
    }
  }
  else {
    if(chapter.questions.length > 0) {
      for(let i = 0; i < chapter.questions.length; i++) {
        //Type 9 is "Information", must be ignored as there is no response needed
        if(chapter.questions[i].type !== 9) {
          num_questions++;

          if(chapter.questions[i].answer?.response) {
            num_answers++;
          }
        }
      }
    }
  }

  return { num_questions, num_answers };
};

export const findCurrentQuestion = (chapter, tag) => {
  if(tag === 'GUESTS' || tag === 'STAFF' || tag === 'STYLE') {
    return chapter._id;
  }
  else {
    return chapter.questions.length > 0 ? chapter.questions[0]["_id"] : null;
  }
};

/**
 * this function disables the fireworks question options for couples
 * @param {*} question 
 * @returns 
 */
export const removeFireworksOptionsFromQuestion = (question) => {
  const options = question?.options;
  let responses = [];
  if (question?.answer?.response && question?.answer?.response?.length > 0 && isArray(question?.answer?.response)) {
    responses = question?.answer?.response.map(res => res?.id);
  }  
  
  let idsToRemove = [
    '5e34140cc51c94736f29dd16',  // Fogo de artifício - 85 disparos - Dourado, prata, branco e azul
    '63dfe12ca005ab2377537147',  // Fogo de artifício - 85 disparos - Multicolor
    '5e3415dfc51c941fbf29dd1f',  // Fogo de artifício - 115 disparos - Dourado, prata, branco e azul
    '63dfe19da005ab547253715a'   // Fogo de artifício - 115 disparos - Multicolor
  ];

  if (responses && responses?.length > 0) {
    idsToRemove = idsToRemove.filter(id => !responses.includes(id));
  }

  const filteredOptions = options.filter(obj => !idsToRemove.includes(obj._id));

  return { options: filteredOptions };
};
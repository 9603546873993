import React from "react";
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {
  DetailContainer,
  FirstRow,
  SecondRow,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  Icon,
  IconMobile,
  MainCategory,
  Category,
  QuestionTitleCategory,
  SmallCategory,
  SmallCategoryTitle,
  RoomPlanStatus,
  TrendMainDishesWarning
} from "./DecorationNavigationStyles";
import LongArrowLeft from '../../components/svg/LongArrowLeft';
import moment from "moment";
import { CountdownWedding } from "../../pages/guests/RoomPlanStyles";

const DecorationNavigation = ({
  returnUrl,
  mainCategory,
  currentQuestion,
  currentQuestionUrl,
  nextQuestion,
  nextQuestionUrl,
  prevQuestion,
  prevQuestionUrl,
  color,
  history,
  translate,
  upgradeSection = false,
  mainCategoryId,
  weddingDate,
  validatedRoomPlan = false,
  chapter,
  wedding
}) => {
  const calcMissingTime = () => {
    const date = moment.utc(weddingDate).startOf('day');
    const now = moment.utc().startOf('day');
    const diffDays = date.diff(now, 'days');
    return diffDays;
  };

  const textMissingTime = () => {
    const diffDays = calcMissingTime();
    if (diffDays <= 0) return ``;
    else return `${translate('TO_DO')} ${diffDays} ${translate('DAYS')}`;
  };

  const showTime = () => {
    const date = moment.utc(weddingDate).startOf('day');
    const now = moment.utc().startOf('day');
    const diffDays = date.diff(now, 'days');
    return mainCategoryId && (mainCategoryId == '65b37c49b42ba97e4a0db4c9' || mainCategoryId == '65afd1a7b42ba97e4a0db4b3')
      ? (diffDays <= 0 ? false : true)
      : false;
  }

  const blockedRoomPlan = () => {
    return mainCategoryId && (mainCategoryId == '65b37c49b42ba97e4a0db4c9' || mainCategoryId == '65afd1a7b42ba97e4a0db4b3')
      && validatedRoomPlan;
  }

  const showTrendMainDishesWarning = () => {
    if (
      wedding?.upgrade?.tag === 'TREND' &&
      (chapter?.questions[0]?._id === '5e1e547e6c3074ee1a29e567' || chapter?.questions[0]?._id === '5e1e53826c3074ded429e565')
    ) {
      return true;
    }

    return false;
  }

  return (
    <DetailContainer>
      <FirstRow>
        {returnUrl &&
          <React.Fragment>
            <FirstColumn>
              <Icon color={color} onClick={() => history.push(returnUrl)}><LongArrowLeft /></Icon>
            </FirstColumn>
            <SecondColumn showTime={showTime()}>
              <IconMobile color={color} onClick={() => history.push(returnUrl)}><LongArrowLeft /></IconMobile>
            </SecondColumn>
          </React.Fragment>
        }
        {showTime() && <ThirdColumn>
          <CountdownWedding showWarning={calcMissingTime() < 19}>
            <i className="fa-regular fa-clock"></i>
            {textMissingTime()}
          </CountdownWedding>
        </ThirdColumn>
        }
        {mainCategory &&
          <ThirdColumn>
            <MainCategory color={color}>{mainCategory}</MainCategory>
          </ThirdColumn>
        }
      </FirstRow>
      <SecondRow>
        <FirstColumn>
          {
            prevQuestion &&
            <React.Fragment>
              {!upgradeSection && <SmallCategoryTitle position="right">{translate('PREVIOUS_STAGE')}:</SmallCategoryTitle>}
              <SmallCategory position="right" onClick={() => history.push(prevQuestionUrl)} hasTitle={!upgradeSection}>{prevQuestion}</SmallCategory>
            </React.Fragment>
          }
        </FirstColumn>
        <SecondColumn roomPlan={blockedRoomPlan()}>
          <QuestionTitleCategory roomPlan={blockedRoomPlan()} color={color} position="center" onClick={() => history.push(currentQuestionUrl)}>{currentQuestion}</QuestionTitleCategory>
          {blockedRoomPlan() && <RoomPlanStatus>{translate('GUEST_LIST_CLOSED')}</RoomPlanStatus>}
          {showTrendMainDishesWarning() && <TrendMainDishesWarning>{translate('TREND_WARNING_MAIN_DISHES')}</TrendMainDishesWarning>}
        </SecondColumn>
        <ThirdColumn>
          {
            nextQuestion &&
            <React.Fragment>
              {!upgradeSection && <SmallCategoryTitle position="left">{translate('NEXT_STAGE')}:</SmallCategoryTitle>}
              <SmallCategory position="left" onClick={() => history.push(nextQuestionUrl)} hasTitle={!upgradeSection}>{nextQuestion}</SmallCategory>
            </React.Fragment>
          }
        </ThirdColumn>
      </SecondRow>
    </DetailContainer>
  );
}

export default withLocalize(withRouter(DecorationNavigation));
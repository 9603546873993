import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;

  @media ${device.desktop} {
    margin-bottom: 30px;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.primaryBorderColor};
  padding-top: 30px;
  padding-bottom: 30px;

  @media ${device.desktopL} {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media ${device.tablet} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.primaryBorderColor};
`;

export const FirstColumn = styled.div`
  width: 100%;
  padding-right: 60px;
  width: 20%;

  @media ${device.laptopL} {
    width: 25%;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const SecondColumn = styled.div`
  width: 100%;
  width: 60%;
  ${p => p?.roomPlan && `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  `}

  @media ${device.laptopL} {
    width: 50%;
  }

  @media ${device.tablet} {
    padding-left: 10px;
    width: ${p => p?.showTime ? '30%' : '60%'};
  }
`;

export const ThirdColumn = styled.div`
  width: 100%;
  padding-left: 60px;
  width: 20%;

  @media ${device.laptopL} {
    width: 25%;
  }

  @media ${device.laptop} {
  }

  @media ${device.tablet} {
    width: 40%;
  }

  @media ${device.tabletS} {
    padding-left: 30px;
  }
`;

export const RoomPlanStatus = styled.div`
  background-color: ${p => p.theme.inputErrorColor};
  color: #f7f7f7;
  padding: 4px 8px;
  margin-left: 15px;
`;

export const Icon = styled.div`
  width: 100%;
  text-align: right;
  cursor: pointer;
  
  & svg {
    width: 24px;
    height: 24px;

    & #arrowleft {
      fill: ${p => p.color ? p.color : '#000000'};
      transition: fill 0.5s linear;
    }

    &:hover {
      & #arrowleft {
        fill: ${p => p.theme.secondaryCTA}; 
      }
    }
  }
`;

export const IconMobile = styled.div`
  width: 100%;
  text-align: left;
  opacity: 0;
  cursor: pointer;
  
  & svg {
    width: 18px;
    height: 18px;

    & #arrowleft {
      fill: ${p => p.color ? p.color : '#000000'};
      transition: fill 0.5s linear;
    }

    &:hover {
      & #arrowleft {
        fill: ${p => p.theme.secondaryCTA}; 
      }
    }
  }

  @media ${device.tablet} {
    opacity: 1;
  }
`;

export const MainCategory = styled.div`
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  word-break: break-word;
  color: ${p => p.color ? p.color : '#000000'};
  padding-right: 2px;
  font-size: 20px;
  line-height: 31px;
  
  @media ${device.desktopXL} {
    font-size: 18px;
    line-height: 28px;
  }

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 26px;
  }

  @media ${device.laptop} {
    font-size: 14px;
    line-height: 24px;
  }

  @media ${device.tabletS} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const QuestionTitleCategory = styled(titleH2)`
    background-color: #5E6B56;
    color: white;
    padding: 10px 0px 10px 0px;
    font-weight: 600;
`;

export const TrendMainDishesWarning = styled.div`
  display: flex;
  text-align: left;  
  justify-content: left;
  font-size: 16px;
`;

export const Category = styled(titleH2)`
  color: ${p => p.color ? p.color : p?.theme?.primaryColor};
  opacity: ${p => p.position === 'center' ? '1' : '0.2'};
  text-align: ${p => p.position === 'left' ? 'right' : 'left'};
  white-space: ${p => p.position === 'center' ? 'initial' : 'nowrap'};
  direction: ${p => p.position === 'left' ? 'rtl' : 'ltr'};
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  width: ${p => p?.roomPlan ? 'auto' : '100%'};
  cursor: pointer;
  transition: opacity 0.5s linear;
  user-select: none;

  &:hover {
    opacity: ${p => p.position === 'center' ? '0.4' : '0.4'};
  }

  @media ${device.tablet} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const SmallCategoryTitle = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: ${p => p.position};
  color: #000000;
  padding-top: 15px;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.laptop} {
    font-size: 14px;
    line-height: 22px;
  }

  @media ${device.tablet} {
    padding-top: 10px;
  }
`;

export const SmallCategory = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: #CCCCCC;
  text-align: ${p => p.position};
  cursor: pointer;
  transition: opacity 0.5s linear;
  user-select: none;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  padding-bottom: 15px;
  padding-top: ${({ hasTitle }) => hasTitle ? '0px' : '18px'};

  &:hover {
    opacity: ${p => p.position === 'center' ? '0.4' : '0.4'};
  }

  @media ${device.desktop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 10px;
    padding-top: ${({ hasTitle }) => hasTitle ? '0px' : '12px'};
  }
`;
import { findPageByID, findPageByTag } from './PageInfo';

const findBaseUrl = (tag) => {
  switch (tag) {
    case 'ABOUT_US':
      return 'about-us';
    case 'CEREMONY':
      return 'ceremony';
    case 'STYLE':
      return 'our-style';
    case 'DAY':
      return 'our-day';
    case 'STAFF':
      return 'staff';
    case 'GUESTS':
      return 'guests';
    case 'FOOD':
      return 'food-selection';
    case 'DRINKS':
      return 'drink-selection';
    case 'DECORATION':
      return 'decoration';
    case 'PARTY':
      return 'party-selection';
    default:
      return undefined;
  }
}

const findTag = (tag) => {
  switch (tag) {
    case 'ABOUT_US':
      return 'ABOUT_US';
    case 'CEREMONY':
      return 'CEREMONY';
    case 'STYLE':
      return 'OUR_STYLE';
    case 'DAY':
      return 'OUR_DAY';
    case 'STAFF':
      return 'STAFF';
    case 'GUESTS':
      return 'GUESTS';
    case 'FOOD':
      return 'FOOD_SELECTION';
    case 'DRINKS':
      return 'DRINK_SELECTION';
    case 'DECORATION':
      return 'DECORATION';
    case 'PARTY':
      return 'PARTY_SELECTION';
    default:
      return undefined;
  }
}

const buildUrl = (baseUrl, id) => {
  return id ? `/${baseUrl}/${id}` : `/${baseUrl}`;
}

const returnObj = (name, url, tag = '') => {
  return { 'name': name, 'url': url, 'tag': tag };
}

/********************************** NEXT **********************************/

export const findNextQuestion = (sections, chapters, chapter, currentQuestion) => {
  let res = undefined, baseUrl = undefined, page = undefined, foundCurrent = 0;

  for(let i = 0; i < chapter.questions.length; i++) {
    //Found the "Next Question"
    if(foundCurrent === 1) {
      page = findPageByID(sections, chapter.section);
      baseUrl = findBaseUrl(page?.tag);

      res = returnObj(chapter.questions[i].title, buildUrl(baseUrl, chapter.questions[i]._id));
      break;
    }

    //If we found the current question, in the next loop will be the question we want
    if(chapter.questions[i]._id === currentQuestion) {
      foundCurrent = 1;
    }
  }
 
  //If we didn't find any question OR found the current question, but don't have any result,
  //then the current question was the last. Must return the first question of the next chapter
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    const nextChapter = findNextChapter(sections, chapter.section, chapters, chapter._id);

    if(nextChapter) {
      page = findPageByID(sections, nextChapter.section);
      baseUrl = findBaseUrl(page?.tag);
      
      //Specific pages, they don't have questions so we use the Chapter ID
      if(page?.tag === 'STYLE' || page?.tag === 'GUESTS' || page?.tag === 'STAFF') {
        res = returnObj(nextChapter.name, buildUrl(baseUrl, nextChapter._id));
      }
      //Otherwise, get the first question of the Chapter
      else if(nextChapter.questions.length > 0) {
        res = returnObj(nextChapter.questions[0].title, buildUrl(baseUrl, nextChapter.questions[0]._id));
      }
    }
    else {
      const nextSection = findNextSection(sections, chapter.section);
 
      if(nextSection) {
        baseUrl = findBaseUrl(nextSection.tag);

        //If there are Chapters in the next Section
        if(nextSection.chapters && nextSection.chapters.length > 0) {
          //If there are questions in the first Chapter
          if(nextSection.chapters[0].questions && nextSection.chapters[0].questions.length > 0) {
            res = returnObj(nextSection.chapters[0].questions[0].title, buildUrl(baseUrl, nextSection.chapters[0].questions[0]._id));
          }
          //Only for these 3 sections we can send directly to the Chapter ID
          else if(nextSection.tag === 'STYLE' || nextSection.tag === 'GUESTS' || nextSection.tag === 'STAFF') {
            res = returnObj(nextSection.chapters[0].name, buildUrl(baseUrl, nextSection.chapters[0]._id));
          }
          //Otherwise, send to the section URL directly
          else {
            res = returnObj('', buildUrl(baseUrl, ''), findTag(nextSection.tag));
          }
        }
        //Otherwise, send to the section URL directly
        else {
          res = returnObj('', buildUrl(baseUrl, ''), findTag(nextSection.tag));
        }
      }
    }
  }

  return res;
};

export const findNextChapter = (sections, currentSection, chapters, currentChapter) => {
  chapters = removeRoomPlanFromChapters(chapters);
  
  let page = findPageByID(sections, currentSection);
  let res = undefined, foundCurrent = 0;
  
  for(let i = 0; i < chapters.length; i++) {
    //Found the "Next Chapter"
    //If tag === 'STYLE' OR 'GUESTS' OR 'STAFF', we don't have questions but still must return the chapter
    if(foundCurrent === 1 && (chapters[i].questions.length > 0 || page?.tag === 'STYLE' || page?.tag === 'GUESTS' || page?.tag === 'STAFF')) {
      res = chapters[i];
      break;
    }

    //If we found the current chapter, in the next loop will be the chapter we want
    if(chapters[i]._id === currentChapter) {
      foundCurrent = 1;
    }
  }

  //If we didn't find any chapter OR found the current chapter, but don't have any result, 
  //then the current chapter was the last. Must return the first chapter of the next section
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    const nextSection = findNextSection(sections, currentSection);

    //If there are Chapters in the next Section
    if(nextSection.chapters && nextSection.chapters.length > 0) {
      page = findPageByID(sections, nextSection._id);

      //If the next section is "STAFF" we don't search for questions, we simply return the first Chapter
      if(page?.tag === 'STAFF') {
        res = nextSection.chapters[0];
      }
      else {
        //Get the next Chapter that has questions
        for(let i = 0; i < nextSection.chapters.length; i++) {
          if(nextSection.chapters[i].questions && nextSection.chapters[i].questions.length > 0) {
            res = nextSection.chapters[i];
            break;
          }
        }
      }
    }
  }

  return res;
};

export const findNextSection = (sections, currentSection) => {
  //Remove "Upgrade" section as it's not a part of the navigation
  sections = removeUpgradeSection(sections);

  let res = undefined, foundCurrent = 0;

  for(let i = 0; i < sections.length; i++) {
    //Found the "Next Section"
    if(foundCurrent === 1) {
      res = sections[i];
      break;
    }

    //If we found the current section, in the next loop will be the section we want
    if(sections[i]._id === currentSection) {
      foundCurrent = 1;
    }
  }

  //If we didn't find any section OR found the current section, but don't have any result, 
  //then the current section was the last. Must return the first section
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    res = sections[0];
  }

  return res;
};

/********************************** PREVIOUS **********************************/

export const findPrevQuestion = (sections, chapters, chapter, currentQuestion) => {
  let res = undefined, baseUrl = undefined, page = undefined, foundCurrent = 0;

  //If "currentQuestion === 0", we just want the last question of the previous chapter
  if(currentQuestion) {
    for(let i = 0; i < chapter.questions.length; i++) {
      //If we found the current question, in the previous loop was the question we want. 
      //So we break the cycle and maintain the value of the last question
      if(chapter.questions[i]._id === currentQuestion) {
        foundCurrent = 1;
        break;
      }

      page = findPageByID(sections, chapter.section);
      baseUrl = findBaseUrl(page?.tag);

      res = returnObj(chapter.questions[i].title, buildUrl(baseUrl, chapter.questions[i]._id));
    }
  }

  //If we found the current question, but don't have any result, 
  //then the current question was the first. Must return the last question of the previous chapter
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    const prevChapter = findPrevChapter(sections, chapter.section, chapters, chapter._id);

    if(prevChapter) {
      page = findPageByID(sections, prevChapter.section);
      baseUrl = findBaseUrl(page?.tag);
      
      //Specific pages, they don't have questions so we use the Chapter ID
      if(page?.tag === 'STYLE' || page?.tag === 'GUESTS' || page?.tag === 'STAFF') {
        res = returnObj(prevChapter.name, buildUrl(baseUrl, prevChapter._id));
      }
      //Otherwise, get the last question of the Chapter
      else if(prevChapter.questions.length > 0) {
        res = returnObj(prevChapter.questions[prevChapter.questions.length - 1].title, buildUrl(baseUrl, prevChapter.questions[prevChapter.questions.length - 1]._id));
      }
    }
    else {
      const prevSection = findPrevSection(sections, chapter.section);

      if(prevSection) {
        baseUrl = findBaseUrl(prevSection.tag);

        //If there are Chapters in the previous Section
        if(prevSection.chapters && prevSection.chapters.length > 0) {
          //If there are questions in the last Chapter, return the last question
          if(prevSection.chapters[prevSection.chapters.length - 1].questions && prevSection.chapters[prevSection.chapters.length - 1].questions.length > 0) {
            res = returnObj(prevSection.chapters[prevSection.chapters.length - 1].questions[prevSection.chapters[prevSection.chapters.length - 1].questions.length - 1].title, buildUrl(baseUrl, prevSection.chapters[prevSection.chapters.length - 1].questions[prevSection.chapters[prevSection.chapters.length - 1].questions.length - 1]._id));
          }
          //Only for these 3 sections we can send directly to the Chapter ID
          else if(prevSection.tag === 'STYLE' || prevSection.tag === 'GUESTS' || prevSection.tag === 'STAFF') {
            res = returnObj(prevSection.chapters[prevSection.chapters.length - 1].name, buildUrl(baseUrl, prevSection.chapters[prevSection.chapters.length - 1]._id));
          }
          //Otherwise, send to the section URL directly
          else {
            res = returnObj('', buildUrl(baseUrl, ''), findTag(prevSection.tag));
          }
        }
        //Otherwise, send to the section URL directly
        else {
          res = returnObj('', buildUrl(baseUrl, ''), findTag(prevSection.tag));
        }
      }
    }
  }

  return res;
};

export const findPrevChapter = (sections, currentSection, chapters, currentChapter) => {
  let page = findPageByID(sections, currentSection);
  let res = undefined, foundCurrent = 0;

  for(let i = 0; i < chapters.length; i++) {
    //If we found the current chapter, in the previous loop was the chapter we want. 
    //So we break the cycle and maintain the value of the last chapter
    if(chapters[i]._id === currentChapter) {
      foundCurrent = 1;
      break;
    }

    //If tag === 'STYLE' OR 'GUESTS' OR 'STAFF', we don't have questions but still must return the chapter
    if(chapters[i].questions.length > 0 || page?.tag === 'STYLE' || page?.tag === 'GUESTS' || page?.tag === 'STAFF') {
      res = chapters[i];
    }
  }

  //If we didn't find any chapter OR we found the current chapter, but don't have any result, 
  //then the current chapter was the first. Must return the last chapter of the last section
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    const prevSection = findPrevSection(sections, currentSection);
  
    //If there are Chapters in the previous Section
    if(prevSection.chapters && prevSection.chapters.length > 0) {
      page = findPageByID(sections, prevSection._id);

      //If the previous section is "STAFF" we don't search for questions, we simply return the last Chapter
      if(page?.tag === 'STAFF' || page?.tag === 'STYLE') {
        res = prevSection.chapters[prevSection.chapters.length - 1];
      }
      else {
        //Get the last Chapter that has questions
        for(let i = prevSection.chapters.length - 1; i >= 0; i--) {
          if(prevSection.chapters[i].questions && prevSection.chapters[i].questions.length > 0) {
            res = prevSection.chapters[i];
            break;
          }
        }
      }
    }
  }

  return res;
};

export const findPrevSection = (sections, currentSection) => {
  //Remove "Upgrade" section as it's not a part of the navigation
  sections = removeUpgradeSection(sections);

  let res = undefined, foundCurrent = 0;

  for(let i = 0; i < sections.length; i++) {
    //If we found the current section, in the previous loop was the section we want. 
    //So we break the cycle and maintain the value of the last section
    if(sections[i]._id === currentSection) {
      foundCurrent = 1;
      break;
    }

    res = sections[i];
  }

  //If we didn't find any section OR found the current section, but don't have any result,
  //then the current section was the first. Must return the last section
  if(foundCurrent === 0 || (foundCurrent === 1 && !res)) {
    res = sections[sections.length - 1];
  }

  return res;
};

/********************************** OTHERS **********************************/

export const findFirstQuestion = (section) => {
  let res = undefined;
  const baseUrl = findBaseUrl(section.tag);

  if(baseUrl && section && section.chapters && section.chapters.length > 0) {
    if(section.chapters[0].questions && section.chapters[0].questions.length > 0) {
      res = returnObj(section.chapters[0].questions[0].title, buildUrl(baseUrl, section.chapters[0].questions[0]._id));
    }
  }

  return res;
}

export const findFirstChapter = (section) => {
  let res = undefined;
  const baseUrl = findBaseUrl(section.tag);

  if(baseUrl && section && section.chapters && section.chapters.length > 0) {
    res = returnObj(section.chapters[0].name, buildUrl(baseUrl, section.chapters[0]._id));
  }

  return res;
}

export const findLastChapter = (section) => {
  let res = undefined;
  const baseUrl = findBaseUrl(section.tag);

  if(baseUrl && section && section.chapters && section.chapters.length > 0) {
    res = returnObj(section.chapters[section.chapters.length - 1].name, buildUrl(baseUrl, section.chapters[section.chapters.length - 1]._id));
  }

  return res;
}

export const removeUpgradeSection = (sections) => {
  const index = sections.findIndex(function(page) {
    return page.tag === 'UPGRADE';
  });
  
  if(index > -1) {
    sections.splice(index, 1);
  }

  return sections;
}

export const isLastQuestion = (sections, id) => {
  //The last section is "PARTY SELECTION", so we just get the ID of the last question and compare it to the argument received
  const page = findPageByTag(sections, 'PARTY');
  let lastQuestion = 0;

  if(page?.chapters && page.chapters.length > 0) {
    const lastChapter = page.chapters[page.chapters.length - 1];

    if(lastChapter && lastChapter.questions && lastChapter.questions.length > 0) {
      lastQuestion = lastChapter.questions[lastChapter.questions.length - 1]._id;
    }
  }

  return lastQuestion === id;
}

export const removeRoomPlanFromChapters = (chapters) => {
  const roomPlandChapterIdx = chapters.findIndex(c => c?._id === '5e2adaa747f38787fd9e8424');
  if (roomPlandChapterIdx != -1) {
    chapters.splice(roomPlandChapterIdx, 1);
  }

  return chapters
}
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SpinLoading } from "../../styles/BasicStyles";
import LongArrowLeft from "../../assets/icons/ic_arrow_left.svg";
import { GetContentByCategory, GetContentFile, GetNextWeddings } from "../../infra/requests/InformationRequests";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import Document from "../../components/document/Document";
import {
  DetailContent,
  DetailContainer,
  Title,
  BackButton,
  DocumentsSection,
  NextWeddingsTitle,
  Backdrop,
  DocumentPreview,
  Subtitle
} from "./InformationStyles";
import Slider from '../../components/slider/Slider';
import Wedding from "../../components/wedding/Wedding";
import ReactPlayer from "react-player";

class InformationPageDetail extends Component {
  state = {
    loading: true,
    content: {},
    nextWeddings: [],
    documentLoading: false,
    videoLoading: false
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    this.getContent();
  }

  getContent = async () => {
    const { history, match: { params }, activeLanguage } = this.props;

    const { data } = await GetContentByCategory(params.id);

    if (!data) {
      history.push('/404');
      return false;
    }

    if (data && data.link && data.link[activeLanguage.code]) this.setState({ documentLoading: true });
    if (data && data.videoLink && data.videoLink[activeLanguage.code]) this.setState({ videoLoading: true });

    let nextWeddings = [];
    if (data.category.tag === 'NEXT_EVENTS_DECORATION') {
      const res = await GetNextWeddings();

      if (res.success) {
        nextWeddings = res.data;
      }
    }

    this.setState({
      content: data,
      loading: false,
      nextWeddings
    });
  };

  handleIfrmeLoad = () => {
    // iframe loaded
    this.setState({ documentLoading: false });
    this.setState({ videoLoading: false });
  }

  render() {
    const { translate, history, activeLanguage } = this.props;
    const { content, nextWeddings, loading, documentLoading, videoLoading } = this.state;
    const documentLink = content && content.link && content.link[activeLanguage.code] ?
      content.link[activeLanguage.code].includes('drive.google.com') &&
        content.link[activeLanguage.code].includes('/view')
        ? content.link[activeLanguage.code].replace('/view', '/preview')
        : content.link[activeLanguage.code]
      : null;

    const videoLink = content && content.videoLink && content.videoLink[activeLanguage.code] ?
      content.videoLink[activeLanguage.code].includes('drive.google.com') &&
        content.videoLink[activeLanguage.code].includes('/view')
        ? content.videoLink[activeLanguage.code].replace('/view', '/preview')
        : content.videoLink[activeLanguage.code]
      : null;

    if (loading) return <SpinLoading />;

    return (
      <DetailContent>
        <DetailContainer>
          <BackButton
            src={LongArrowLeft}
            onClick={() => history.push("/information")}
          />
          <Title>{translate(content?.category?.tag || "")}</Title>
          <DraftJSContent content={content?.text} />
          {documentLink && <React.Fragment>
            {/* <Subtitle>{translate("DOCUMENT")}</Subtitle> */}
            <DocumentPreview>
              {documentLoading && <Backdrop>
                <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
              </Backdrop>}
              <iframe
                id="document"
                onLoad={this.handleIfrmeLoad}
                style={{ width: '100%', height: 500, borderRadius: 5, borderWidth: 1 }}
                src={documentLink}
              ></iframe>
            </DocumentPreview>
          </React.Fragment>
          }
          {videoLink && <React.Fragment>
            {/* <Subtitle>{translate("VIDEO")}</Subtitle> */}
            <DocumentPreview>
              {videoLoading && <Backdrop>
                <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
              </Backdrop>}
              <iframe
                id="document"
                onLoad={this.handleIfrmeLoad}
                style={{ width: '100%', height: 500, borderRadius: 5, borderWidth: 1 }}
                src={videoLink}
                autoplay={0}
                controls
                allowFullScreen
              ></iframe>
            </DocumentPreview>
          </React.Fragment>
          }
        </DetailContainer>
        {
          content.gallery && content.gallery.length > 0 &&
          <Slider gallery={content.gallery} />
        }
        <DetailContainer>
          {content?.files && content.files.length > 0 && (
            <DocumentsSection>
              {content.files.map(file => (
                <Document key={file._id} document={file} />
              ))}
            </DocumentsSection>
          )}
        </DetailContainer>
        {
          nextWeddings.length > 0 &&
          <DetailContainer>
            <NextWeddingsTitle>{translate('NEXT_WEDDINGS')}</NextWeddingsTitle>
            {
              nextWeddings.map((wedding, index) =>
                <Wedding key={index} wedding={wedding} />
              )
            }
          </DetailContainer>
        }
      </DetailContent>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.info.content_categories || []
});

export default withRouter(withLocalize(connect(mapStateToProps)(InformationPageDetail)));
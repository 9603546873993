import React from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import CheckboxInput from "../inputs/CheckboxInput";
import {
  ListContainer,
  ListText,
  CheckboxContainer,
  ListQuantity,
  ListTextContainer,
  ListSuccessMessage,
  ListWarningMessage
} from "./OptionCardStyles";
import Quantity from "../inputs/Quantity";
import Alert from "../alert/Alert";
import { CostTypes, checkOption } from '../../infra/services/utils/Options';
import { Icon, Tooltip } from "antd";
import { isString } from "./OptionUtils";
import styledComponents from "styled-components";
import { PrimaryColour } from "../../styles/Colours";

export const PriceWithWarning = styledComponents.div`
  display: flex;
  align-content: center;
  align-items: center;
  
  .anticon { 
    color: ${({ premium }) => premium ? PrimaryColour : '#fb635b'};
    margin-right: 5px;
    font-size: 15px;
  }

  .span {
    font-size: 13px;
  }
`;

const ListCard = ({ type, record, canEditSection, isProcessClosed, input, text, color, question, wedding, translate, activeLanguage }) => {
  // let selected = false;
  let selected = null;
  let disabled = false;
  const isPaymentMethodQuestion = question && question._id === '5e73610729196b814fb9887f' ? true : false;
  const isPremiumOption = wedding && wedding.upgrade && wedding.upgrade.hasPremiumOptions && record && record.isPremiumOption ? true : false;
  const isTrendOption = wedding && wedding.upgrade && wedding.upgrade.hasPremiumOptions && record && record.isTrendOption ? true : false;

  const isPremiumOptionOption = record && record?.isPremiumOption ? true : false;
  const isTrendOptionOption = record && record?.isTrendOption ? true : false;

  const showUpgradeIcon = (record && (record?.isTrendFood || record?.isPremiumFood || record?.isTrendOption || record?.isPremiumOption)) ? true : false;

  //Options List
  if (type === 1) {
    selected = input?.value && Array.isArray(input?.value) && input?.value.find(x => x.id === record._id);

    if (input?.value.length > 0 && !input?.value.find(x => x.id === record._id)) {
      //We have to check every element in input.value
      //If at least one option still exists in this question, then we can disable this option
      //If not then we cannot disable any option as the current option doesn't exist anymore
      for (let i = 0; i < input.value.length; i++) {
        if (checkOption(input.value[i], question, wedding.wedding_place)) {
          disabled = true;
          break;
        }
      }
    }
  }
  //Confirmation Options
  else if (type === 2) {
    selected = input?.value && input?.value.find(x => x.id === record);

    if (input?.value.length > 0 && !input?.value.find(x => x.id === record)) {
      //We have to check every element in input.value
      //If at least one option still exists in this question, then we can disable this option
      //If not then we cannot disable any option as the current option doesn't exist anymore
      for (let i = 0; i < input.value.length; i++) {
        if (checkOption(input.value[i], question, wedding.wedding_place)) {
          disabled = true;
          break;
        }
      }
    }
  }

  const toggleOption = () => {
    if (!disabled) {
      if (isProcessClosed) {
        Alert.new({
          type: "success",
          title: translate('BLOCKED_ANSWER_TITLE'),
          text: translate('PROCESS_CLOSED_TEXT')
        });
      }
      else if (canEditSection) {
        const list = [...input.value];
        let index = 0;

        //Options List
        if (type === 1) {
          index = list.findIndex(x => x.id === record._id);

          if (index >= 0) list.splice(index, 1);
          else {
            if (isPaymentMethodQuestion) {
              list.unshift({ id: record._id, qtd: 1 })
            } else if (wedding?.upgrade && wedding?.upgrade?.hasPremiumOptions && record && (record.isPremiumOption || record.isTrendOption)) {
              if (wedding?.upgrade?.tag === 'PRIME' && record?.isPremiumOption) {
                list.push({ id: record._id, qtd: 1, cost: 0 });
              } else if (wedding?.upgrade?.tag === 'TREND' && record?.isTrendOption) {
                list.push({ id: record._id, qtd: 1, cost: 0 });
              } else {
                list.push({ id: record._id, qtd: 1, cost: record?.cost_price ? record?.cost_price : 0 });
              }
            } else list.push({ id: record._id, qtd: 1 });
          }
        }
        //Confirmation Options
        else if (type === 2) {
          index = list.findIndex(x => x.id === record);

          if (index >= 0) list.splice(index, 1);
          else list.push({ id: record, qtd: 1 });
        }

        input.onChange(list);
      }
      else {
        Alert.new({
          type: "success",
          title: translate('BLOCKED_ANSWER_TITLE'),
          text: translate('BLOCKED_ANSWER_TEXT')
        });
      }
    }
  };

  const style = isPaymentMethodQuestion && selected ? {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start'
  } : {};

  const showWarningPriceUpdated = (selected && selected.cost !== undefined && record && (isPremiumOption || isTrendOption) && parseFloat(selected.cost) > 0)
    || (selected && selected.cost !== undefined && record && !(isPremiumOption || isTrendOption) && parseFloat(selected.cost) !== record.cost_price)
    ? true : false;

  const renderPrice = () => {
    let sufix = "",
      prefix = "";

    if (record.cost_type === CostTypes.QUANTITY) {
      sufix = ` ${translate("QUANTITY_UNI")}`;
    } else if (record.cost_type === CostTypes.TABLE) {
      sufix = ` ${translate("QUANTITY_TABLE")}`;
    } else if (record.cost_type === CostTypes.GUEST) {
      sufix = ` ${translate("QUANTITY_GUEST")}`;
    } else if (record.cost_type === CostTypes.PEOPLE) {
      sufix = ` ${translate("QUANTITY_PEOPLE")}`;
    }

    let cost = 0;
    if (selected?.cost && selected?.cost !== undefined) {
      cost = isString(selected.cost) ? parseFloat(selected.cost) : selected.cost;
    } else {
      // cost = wedding?.upgrade?.hasPremiumOptions && record?.isPremiumOption
      //   ? record?.costPriceExtra
      //   : record.cost_price;
      cost = record.cost_price;
    }
    // return cost;

    return `${prefix}${cost}€${sufix}`;
  }

  return (
    <ListContainer disabled={disabled} onClick={() => toggleOption()}>
      <CheckboxContainer>
        <CheckboxInput
          checkboxType="square"
          meta={{}}
          input={{ value: selected, onChange: () => null }}
          disabled={disabled}
        />
      </CheckboxContainer>
      <div style={style}>
        <ListTextContainer>
          <ListText color={color}>
            {text ? text : record.title[activeLanguage?.code]}
            {
              (record?.cost || record?.cost_price) &&
              <React.Fragment>&nbsp;- {renderPrice()}</React.Fragment>
            }
            {(record?.cost || record?.cost_price) && (showWarningPriceUpdated || showUpgradeIcon) &&
              <PriceWithWarning premium={!showWarningPriceUpdated && isPremiumOption && isTrendOption}>
                <Icon type={showWarningPriceUpdated ? 'info-circle' : 'sketch'} />
                <span style={{ fontSize: 12 }}>{showWarningPriceUpdated ? translate('WARNING_COST_UPDATED') : (isPremiumOptionOption && isTrendOptionOption)
                  ? translate('WARNING_OPTION_WEDDING_PRIMEANDTREND')
                  : isPremiumOptionOption
                    ? translate('WARNING_OPTION_WEDDING_PRIME')
                    : isTrendOptionOption
                      ? translate('WARNING_OPTION_WEDDING_TREND')
                      : null}</span>
              </PriceWithWarning>
            }
          </ListText>
          {
            selected && record.cost_type === CostTypes.QUANTITY &&
            <ListQuantity>
              <Quantity
                optionID={record._id}
                input={input}
                isProcessClosed={isProcessClosed}
                canEditSection={canEditSection}
              />
            </ListQuantity>
          }
        </ListTextContainer>
        {isPaymentMethodQuestion && selected &&
          <ListTextContainer>
            {selected.confirmed === 'true'
              ? <ListSuccessMessage>
                <Icon type="check-circle" />
                &nbsp;{translate('PAYMENT_METHOD_CONFIRMED')}
              </ListSuccessMessage>
              : <ListWarningMessage>
                <Icon type="exclamation-circle" />
                &nbsp;{translate('PAYMENT_METHOD_CONFIRMATION_PENDING')}
              </ListWarningMessage>}
          </ListTextContainer>
        }
      </div>
    </ListContainer >

  );
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

export default withLocalize(connect(mapStateToProps)(ListCard));
import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH2, titleH3, titleH4 } from "../../styles/TextSizes";

export const Title = styled(titleH2)`
  text-align: left;
  margin-bottom: 30px;

  @media ${device.desktop} {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const InformationSection = styled.div`
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.thirdColor};
  cursor: pointer;
`;

export const InformationTitle = styled(titleH4)`
  position: absolute;
  top: 12px;
  left: 22px;
  width: calc(100% - 44px);
  color: #757575;
  text-align: left;

  @media ${device.mobileM} {
    font-size: 14px;
  }
`;

export const InformationIcon = styled.img`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 36%;

  @media ${device.mobileM} {
    width: 20%;
  }
`;
export const InformationArrow = styled.img`
  position: absolute;
  bottom: 12px;
  right: 22px;
  width: 24px;
`;

export const BackButton = styled.img`
  width: 18px;
  margin-bottom: 10px;
  position: absolute;
  top: 30px;
  left: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    left: 10px;
  }
`;

export const DocumentsSection = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;
`;

export const DetailContent = styled.div`
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
`;

export const DetailContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding: 0 20px;

  @media ${device.tablet} {
    padding: 0 10px;
  }
`;

export const NextWeddingsTitle = styled(titleH3)`
  text-align: left;
  margin-bottom: 30px;
`;

export const DocumentPreview = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 50px;
`;

export const Backdrop = styled.div`
  z-index: 99999;
  background-color: black;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  border-radius: 5px;

  .ant-spin {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 0px !important;
  }
`;

export const Subtitle = styled.div`
  text-align: left;
  color: ${p => p?.theme?.primaryColor};
  font-size: 19px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 10px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;
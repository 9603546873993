import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm, Field, initialize } from "redux-form";
import { connect } from "react-redux";
import {
  DetailContainer,
  Description,
  FormContainer,
  ImageContainer,
} from "./PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import DecorationMenu from "../../components/decorationMenu/DecorationMenu";
import SaveButton from "../../components/buttons/SaveButton";
import DecorationObservation from "../../components/decorationObservation/DecorationObservation";
import {
  findPageByTag,
  findPageByID,
  findQuestionByID,
  findNumberQuestionsChapter,
  removeFireworksOptionsFromQuestion
} from "../../infra/services/utils/PageInfo";
import {
  editObservations,
  editSection,
  processClosed,
} from "../../infra/services/utils/BlockAnswers";
import {
  findNextQuestion,
  findPrevQuestion,
  isLastQuestion,
} from "../../infra/services/utils/PageNavigation";
import ManageQuestionType from "../../components/manageQuestionType/ManageQuestionType";
import ImageComponent from "../../components/images/ImageComponent";
import { AnswerQuestion, CanLoadCoximLacoChairs } from "../../infra/requests/MyWeddingRequests";
import { updateQuestionAction } from "../../redux/Wedding/wedding.actions";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { SpinLoading } from "../../styles/BasicStyles";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";
import ImageModal from "../../../src/components/imageModal/ImageModal";
import Alerts from "../../components/alert/Alert";
import { correctResponseValues } from "../../components/optionCard/OptionUtils";
import { CheckUpdateCoupleTable } from "../../infra/requests/GuestsRequests";
import { Modal } from "antd";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";
import { checkCoupleTableAnswer, removeNotExistingOptions } from "../../infra/services/utils/Options";

const { confirm } = Modal;

const FormValidations = (values) => {
  //console.log('FORM', values);
  return {};
};

class PageDetail extends Component {
  state = {
    question: undefined,
    chapter: undefined,
    nextQuestion: undefined,
    prevQuestion: undefined,
    num_questions: undefined,
    num_answers: undefined,
    canEditSection: undefined,
    isProcessClosed: undefined,
    showImageModal: false,
    selectedImage: undefined,
    ready: false,
    loading: false,
    confirmSubmitting: false,
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    const { decorationInfo, weddingDate, blockDate, history, dispatch, wedding } = this.props;
    const { chapters } = findPageByTag(decorationInfo, this.props.tag);
    const question = findQuestionByID(chapters, this.props.id);

    if (!question) {
      history.push("/404");
      return false;
    }

    // IF QUESTION IS COXIM OR LACO
    let loadCoximLaco = true;
    if (
      question?._id === '5e18a7f686fdaa220deeddc5' ||
      question?._id === '5e18abf786fdaa0cc7eeddcd'
    ) {
      const result = await CanLoadCoximLacoChairs(wedding?._id);      
      if (result?.success) {
        loadCoximLaco = result?.data;
      }
    }
    this.setState({ loadCoximLaco });

    const chapter = findPageByID(chapters, question.chapter);
    let nextQuestion = findNextQuestion(
      decorationInfo,
      chapters,
      chapter,
      question._id
    );
    const prevQuestion = findPrevQuestion(
      decorationInfo,
      chapters,
      chapter,
      question._id
    );
    const { num_questions, num_answers } = findNumberQuestionsChapter(chapter);
    const canEditSection = editSection(weddingDate, this.props.tag);
    // const isProcessClosed = processClosed(weddingDate);
    const isProcessClosed = processClosed(blockDate);

    //Check if it's the last question of the last section
    const lastQuestion = isLastQuestion(decorationInfo, question._id);
    if (lastQuestion) {
      nextQuestion = { tag: "UPGRADE", url: "/upgrade" };
    }

    dispatch(initialize("section_form", {}));

    if (question?.answer) {
      dispatch(
        initialize("section_form", {
          ...question?.answer,
          question_obs: question?.answer?.observations !== "",
        })
      );
    }

    this.setState({
      question,
      chapter,
      nextQuestion,
      prevQuestion,
      num_questions,
      num_answers,
      canEditSection,
      isProcessClosed,
      ready: true
    });
  };

  confirmSubmitModal = async (values) => {
    const { translate } = this.props;
    const { wedding } = this.props;
    const { question } = this.state;
    this.setState({ confirmSubmitting: true });
    // const answer = correctResponseValues(values);

    // Check if couple table question
    if (inGuestMapTest() && question?._id == '5e18723686fdaa6d7aeedd91') {
      const data = checkCoupleTableAnswer(values, question, wedding.wedding_place);
      const resultCheck = await CheckUpdateCoupleTable(data);

      if (resultCheck?.success) {
        if (resultCheck?.data?.showWarning) {
          const ovalLWarning = resultCheck?.data?.showOvalLWarning
            ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
              ? translate('TO_REMOVE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
              : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
                ? translate('TO_RESTORE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
                : null
            : null;

          if (resultCheck?.data?.showOvalLWarning && resultCheck?.data?.tablesToRemoveFilled) {
            this.setState({ showModal: false }, () => {
              Modal.warning({
                title: translate('ATTENTION'),
                content: translate('COUPLE_REMOVE_WITH_OVALL_BLOCKED'),
              });
            })
            return;
          }

          confirm({
            title: translate('UPDATE_COUPLE_TABLE_QUESTION'),
            content: (<div>
              <p>{translate('UPDATE_COUPLE_TABLE_CONTENT')}</p>
              {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
            </div>
            ),
            okText: translate('CHANGE_TABLE'),
            cancelText: translate('CANCEL'),
            onOk: () => {
              this.onSubmit(values);
            },
            onCancel: () => {
              this.setState({ confirmSubmitting: false });
              return;
            },
          });
        } else this.onSubmit(values);
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(resultCheck.message)
        })
        this.setState({ confirmSubmitting: false });
        return;
      }
    }
    // Check if question hasOnlyOneOptions and has options selected with onlyOneAvailable tag
    // else if (question?.hasOnlyOneOptions && answer?.response?.length > 0) {
    //   const respIds = answer?.response?.map(m => m?.id);
    //   const onlyOneOptionsSelected = respIds?.length > 0 && question?.options?.filter(f => f?.onlyOneAvailable)?.length > 0
    //     ? question?.options?.filter(f => f?.onlyOneAvailable).filter(f => respIds.includes(f?._id))
    //     : [];
    //   const hasOnlyOneOptionSelected = onlyOneOptionsSelected?.length > 0;

    //   if (hasOnlyOneOptionSelected) {
    //     confirm({
    //       title: translate('LIMITED_AVAILABILITY_TITLE'),
    //       content: (<div>
    //         <p>{translate('LIMITED_AVAILABILITY_INFO')}</p>
    //         <p><b>{translate('LIMITED_AVAILABILITY_QUESTION')}</b></p>
    //       </div>
    //       ),
    //       okText: translate('CONTINUE'),
    //       cancelText: translate('CANCEL'),
    //       onOk: () => this.onSubmit(values),
    //       onCancel: () => {
    //         this.setState({ confirmSubmitting: false });
    //         return;
    //       },
    //     });
    //   } else this.onSubmit(values);
    // } 
    else {
      this.onSubmit(values);
    }

  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history, dispatch, updateQuestionAction, translate, wedding, food } = this.props;
      const { nextQuestion, question } = this.state;

      if (question?.options && question?.options?.length > 0) {
        values = removeNotExistingOptions(values, question, wedding?.wedding_place, food);
      }
      values = correctResponseValues(values);
      const payload = TransformToFormData({
        ...values,
        question: question._id,
      });

      const result = await AnswerQuestion(payload);

      if (result?.success) {
        dispatch(updateQuestionAction(this.props.tag, result.data));

        history.push(nextQuestion?.url);
      } else {
        if (result?.message === "WEDDING_OPTION_NOT_AVAILABLE") {
          Alerts.new({
            type: 'warning',
            title: translate('WEDDING_OPTION_NOT_AVAILABLE'),
            text: translate('WEDDING_OPTION_NOT_AVAILABLE_EMAIL')
          });
          history.push(nextQuestion?.url);
        } else if (result?.message == 'UPGRADE_BLOCKED_BOTH_MAIN_DISHES') {
          Alerts.new({
            type: 'error',
            title: translate('ERROR'),
            text: translate('UPGRADE_BLOCKED_BOTH_MAIN_DISHES')
          });
        }
      }

      this.setState({
        loading: false,
        confirmSubmitting: false
      });
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
        confirmSubmitting: false
      });
    }
  };

  render() {
    const {
      handleSubmit,
      translate,
      activeLanguage,
      history,
      dirty,
      submitting,
    } = this.props;
    const {
      question,
      chapter,
      nextQuestion,
      prevQuestion,
      num_questions,
      num_answers,
      canEditSection,
      isProcessClosed,
      showImageModal,
      selectedImage,
      ready,
      loading,
      loadCoximLaco
    } = this.state;
    const { confirmSubmitting } = this.state;

    if (!ready) return <SpinLoading />;

    // validate if the question have firework options and if its true it removes that options if necessary
    question.options = removeFireworksOptionsFromQuestion(question)?.options;

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirty && !submitting && !confirmSubmitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <ProgressBar
          type="section"
          num_questions={num_questions}
          num_answers={num_answers}
        />
        <DecorationNavigation
          returnUrl={this.props.url}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={question.title[activeLanguage?.code]}
          currentQuestionUrl={`${this.props.url}/${question._id}`}
          nextQuestion={
            nextQuestion?.tag
              ? translate(nextQuestion?.tag)
              : nextQuestion?.name[activeLanguage?.code]
          }
          nextQuestionUrl={nextQuestion?.url}
          prevQuestion={
            prevQuestion?.tag
              ? translate(prevQuestion?.tag)
              : prevQuestion?.name[activeLanguage?.code]
          }
          prevQuestionUrl={prevQuestion?.url}
          chapter={chapter}
          wedding={this.props.wedding}
        />
        <DecorationMenu
          baseUrl={this.props.url}
          questions={chapter.questions}
        />
        <DetailContainer>
          {question.description && (
            <Description
              dangerouslySetInnerHTML={{
                __html:
                  question.description &&
                  question.description[activeLanguage?.code],
              }}
            />
          )}
          {question.images.length > 0 && (
            <ImageContainer total={question.images.length}>
              {question.images.map((elem, index) => (
                <ImageComponent
                  key={index}
                  url={elem.url}
                  color={elem.color}
                  bgContain={true}
                  zoom={true}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showImageModal: true, selectedImage: elem });
                  }}
                />
              ))}
            </ImageContainer>
          )}
          {showImageModal && (
            <ImageModal
              title={question.title[activeLanguage?.code]}
              image={selectedImage.url}
              color={selectedImage?.color}
              handleCancel={() => this.setState({ showImageModal: false, selectedImage: undefined })}
            />
          )}
          {loadCoximLaco && <FormContainer onSubmit={handleSubmit(this.confirmSubmitModal)}>
            <Field
              name="response"
              component={ManageQuestionType}
              question={question}
              canEditSection={canEditSection}
              isProcessClosed={isProcessClosed}
            />
            {question.observations && (
              <DecorationObservation
                inputLabel={translate("OBSERVATIONS_WD")}
                canEditSection={canEditSection ? editObservations(question) : canEditSection}
                isProcessClosed={isProcessClosed}
              />
            )}
            {canEditSection && !isProcessClosed && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
          </FormContainer>}
          {(!canEditSection || isProcessClosed) && (
            <SaveButton
              htmlType="button"
              text={translate("CONTINUE")}
              textMobile={translate("CONTINUE")}
              withBackground={true}
              onClick={() => history.push(nextQuestion?.url)}
            />
          )}
        </DetailContainer>
      </React.Fragment>
    );
  }
}

const PageDetailForm = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(PageDetail);

const mapStateToProps = (state) => ({
  decorationInfo: state.wedding.structure,
  colors: state.info.colours,
  wedding: state.wedding.wedding,
  weddingDate: state.wedding.wedding.date,
  blockDate: state.wedding.wedding.blockDate,
  food: state.info.food
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ updateQuestionAction }, dispatch);

export default connect(
  mapStateToProps,
  mapActionToProps
)(withLocalize(withRouter(PageDetailForm)));

import React from 'react';
import {withLocalize} from 'react-localize-redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  DecorationContainer,
  Title,
  Subtitle,
  CardsContainer,
  CardsRow
} from './MainPageStyles';
import SectionCard from '../../components/sectionCard/SectionCard';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { findCurrentQuestion, findPageByTag, findNumberQuestionsChapters } from '../../infra/services/utils/PageInfo';

const MainPage = ({ tag, title, url, history, translate, decorationInfo, wedding, guestsInfo, styleInfo, activeLanguage }) => {
  window.scroll({top: 0, left: 0, behavior: 'smooth'});
  document.body.style.overflowY = 'auto';
  
  const { description, chapters } = findPageByTag(decorationInfo, tag);
  const { num_questions, num_answers } = findNumberQuestionsChapters(chapters, tag, guestsInfo, styleInfo);
  
  const renderCard = (chapter, index) => {    
    if (isRoomPlanChapter(chapter?._id)) {
      return;
    }

    let number = index;
    number++;
    if(number < 10) {
      number = `0${number}`;
    }

    const currentQuestion = findCurrentQuestion(chapter, tag);

    return currentQuestion ? 
      <SectionCard 
        key={index}
        chapter={chapter}
        number={number} 
        title={chapter.name[activeLanguage?.code]} 
        text={chapter.description[activeLanguage?.code]}
        onClick={() => history.push(`${url}/${currentQuestion}`)}
        tag={tag}
        guestsInfo={guestsInfo}
        styleInfo={styleInfo}
        wedding={wedding}
        translate={translate}
      />
      :
      null;
  }

  const isRoomPlanChapter = (id) => {
    return id === '5e2adaa747f38787fd9e8424';
  }

  return (
    <React.Fragment>
      <ProgressBar type='total' num_questions={num_questions} num_answers={num_answers}/>
      <DecorationContainer>
        <Title>{translate(title)}</Title>
        <Subtitle dangerouslySetInnerHTML={{__html: description && description[activeLanguage?.code]}}/>
      </DecorationContainer>
      <CardsContainer>
        <CardsRow>
          {
            chapters.length > 0 && chapters?.filter(chapter => findCurrentQuestion(chapter, tag)).map((element, index) => renderCard(element, index))
          }
        </CardsRow>
      </CardsContainer>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({ 
  decorationInfo: state.wedding.structure,
  wedding: state.wedding.wedding
});

export default connect(mapStateToProps)(withLocalize(withRouter(MainPage)));
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  BudgetContainer,
  Title,
  Description,
  Subtitle,
  SectionTitleContainer,
  SectionIcon,
  SectionTitle,
  SectionSubtitle,
  BudgetTotal,
  BudgetGraphsContainer,
  NoRecordsContainer,
  NoRecords,
  BudgetInfo,
  OptionButton,
} from "./BudgetStyles";
import Hand from "../../assets/icons/hand.svg";
import CreditCard from "../../assets/icons/credit.svg";
import Money from "../../assets/icons/money.svg";
import BudgetSection from "../../components/budgetSection/BudgetSection";
import BudgetGraph from "../../components/budgetGraph/BudgetGraph";
import { CreateBudget, MyBudget, RemoveBudget } from "../../infra/requests/MyWeddingRequests";
import moment from "moment";
import { SpinLoading } from "../../styles/BasicStyles";
import { numberFormat } from "../../infra/services/utils/FormatNumber";
import BudgetTablePayments from "../../components/budgetTable/BudgetTablePayments";
import BudgetTableExpenses from "../../components/budgetTable/BudgetTableExpenses";
import { GetUpgradeDetail } from "../../infra/requests/UpgradeRequests";
import { Icon, Popconfirm } from "antd";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";
import { checkPreviousPhasesCompleted, getMissingValue, getPhaseDate } from "./budgetUtils";
import { GraphColors, PrimaryColour } from "../../styles/Colours";
import SaveButton from "../../components/buttons/SaveButton";
import PaymentProofModal from "./PaymentProofModal";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import { FlattenToFormData } from "../../infra/services/validations/TransformToFormData";
import Alerts from "../../components/alert/Alert";
import { WeddingStatus } from "../reserve/ReservePage";
import { SaveWedding, updateAboutUsAction } from "../../redux/Wedding/wedding.actions";
import { UPDATE_ABOUT_US } from "../../redux/ActionsType";

class Budget extends Component {
  state = {
    payments: [],
    expenses: [],
    expensesExtras: [],
    numTables: 0,
    numGuests: 0,
    minGuests: 0,
    numPlaces: 0,
    numChildren: 0,
    numStaff: 0,
    numPax: 0,
    numPaxShow: 0,
    music: 0,
    priceGuest: 0,
    paymentsList: [],
    expensesList: [],
    offersList: [],
    receiptsList: [],
    discountsList: [],
    total: 0,
    offer: null,
    discount: null,
    upgrade: undefined,
    paymentPhases: [],

    iban: '',
    swift: '',
    ready: false,

    isProcessClosed: false,

    receiptModalVisible: false,
    currentReceipt: {},
    receiptOnlyView: false
  };

  componentDidMount() {
    const { info, weddingInfo } = this.props;

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = 'auto';

    const isProcessClosed = processClosed(weddingInfo?.blockDate);
    this.setState({
      iban: info?.configs?.iban || '',
      swift: info?.configs?.swift || '',
      isProcessClosed
    }, () => this.getBudget());
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.info?.configs?.iban != this.props?.info?.configs?.iban) {
      this.setState({ iban: this.props?.info?.configs?.iban || '' });
    }

    if (prevProps?.info?.configs?.swift != this.props?.info?.configs?.swift) {
      this.setState({ swift: this.props?.info?.configs?.swift || '' });
    }
  }

  addReceipt = async (data) => {
    const { weddingInfo } = this.props;
    const { translate, dispatch } = this.props;

    if (!data?.description) data['description'] = ' ';
    data['value'] = 0;
    data['title'] = ' ';
    data['type'] = 'PAYMENT_PROOF';
    data['date'] = moment.utc().toISOString();

    const payload = FlattenToFormData({ ...data, wedding: weddingInfo?._id });
    payload.append('file', data?.receipt?.blob);

    const createdBudget = await CreateBudget(payload);
    if (createdBudget?.success) {
      Alerts.new({
        type: "success",
        title: translate("SUCCESS"),
        text: translate('PAYMENT_PROOF_ADDED_SUCCESS'),
      });

      dispatch(updateAboutUsAction(createdBudget?.data?.wedding));

      this.closeReceiptModal();
      await this.getBudget();
    } else {
      Alerts.new({
        type: "error",
        title: translate("ERROR"),
        text: translate('PAYMENT_PROOF_ADDED_ERROR'),
      });

      this.closeReceiptModal();
    }
  }

  deleteReceipt = async (id) => {
    const { translate } = this.props;

    const removed = await RemoveBudget(id);
    if (removed?.success) {
      Alerts.new({
        type: "success",
        title: translate("SUCCESS"),
        text: translate('PAYMENT_PROOF_REMOVED_SUCCESS'),
      });

      await this.getBudget();
    } else {
      Alerts.new({
        type: "error",
        title: translate("ERROR"),
        text: translate('PAYMENT_PROOF_REMOVED_ERROR'),
      });
    }
  }

  openAddReceiptModal = () => {
    this.setState({ receiptModalVisible: true, receiptOnlyView: false });
  }

  openViewReceiptModal = async (receipt) => {
    this.setState({ receiptModalVisible: true, receiptOnlyView: true, currentReceipt: receipt });
  }

  closeReceiptModal = () => {
    this.setState({ receiptModalVisible: false, receiptOnlyView: false, currentReceipt: {} });
  }

  getBudget = async () => {
    const { weddingInfo } = this.props;

    let { data, success } = await MyBudget();
    let expenses = [],
      expensesExtras = [],
      payments = [],
      paymentsList = [],
      expensesList = [],
      offersList = [],
      receiptsList = [],
      discountsList = [],
      paymentPhases = [];
    let total = 0,
      paid = 0,
      missing = 0,
      numTables = 0,
      numGuests = 0,
      minGuests = 0,
      numPlaces = 0,
      numChildren = 0,
      numStaff = 0,
      numPax = 0,
      numPaxShow = 0,
      priceGuest = 0,
      music = 0,
      offer = 0,
      discount = 0,
      upgrade = undefined;

    if (success) {
      expensesExtras = data.extras;
      numTables = data.tables;
      numGuests = data.guests;
      minGuests = data.min_people;
      numPlaces = data.places;
      numChildren = data.children;
      numStaff = data.staff;
      music = data.music;
      offer = data?.offer;
      discount = data?.discount;
      numPax = data.pax;
      numPaxShow = data.pax_show;
      priceGuest = data.price;
      paymentPhases = data?.paymentPhases || [];

      // eslint-disable-next-line
      for (let key in data.costs) {
        if (key !== 'offer' && key !== 'discount') {
          total += data.costs[key];
        }
      }

      //Offers
      const offers = data.costs.offer || 0;
      //Discounts
      const discounts = data.costs.discount || 0;
      total = total - offers - discounts;

      if (data.budget.length > 0) {
        paymentsList = data.budget.filter((budget) =>
          budget.type === "PAYMENT" ? budget : null
        );
        expensesList = data.budget.filter((budget) =>
          budget.type === "EXPENSE" ? budget : null
        );
        offersList = data.budget.filter((budget) =>
          budget.type === "OFFER" ? budget : null
        );
        receiptsList = data.budget.filter((budget) =>
          budget.type === "PAYMENT_PROOF" ? budget : null
        );
        discountsList = data.budget.filter((budget) =>
          budget.type === "DISCOUNT" ? budget : null
        );

        if (paymentsList.length > 0) {
          for (let i = 0; i < paymentsList.length; i++) {
            paid += paymentsList[i].value;
          }
        }
      }

      missing = total - paid;

      payments = [
        {
          name: "BUDGET_PAID",
          value: paid,
          color: GraphColors.paid,
        },
        {
          name: "BUDGET_MISSING",
          value: missing,
          color: GraphColors.missing,
        },
      ];

      expenses = [
        {
          name: "BUDGET_ADJUDICATED",
          value: data.costs.base,
          color: GraphColors.base,
        },
        {
          name: 'BUDGET_ROOM_PLAN',
          value: data.costs.tables,
          color: GraphColors.roomPlan,
        },
        {
          name: "BUDGET_CEREMONY",
          value: data.costs.ceremony,
          color: GraphColors.ceremony,
        },
        {
          name: "BUDGET_STAFF",
          value: data.costs.staff,
          color: GraphColors.staff,
        },
        {
          name: "BUDGET_FOOD",
          value: data.costs.food,
          color: GraphColors.food,
        },
        {
          name: "BUDGET_DRINK",
          value: data.costs.drinks,
          color: GraphColors.drinks,
        },
        {
          name: "BUDGET_DECORATION",
          value: data.costs.decoration,
          color: GraphColors.decor,
        },
        {
          name: "BUDGET_PARTY",
          value: data.costs.party,
          color: GraphColors.party,
        },
        {
          name: "BUDGET_UPGRADE",
          value: data.costs.upgrade,
          color: GraphColors.upgrade,
        },
        {
          name: "BUDGET_EXPENSES",
          value: data.costs.expense,
          color: GraphColors.expense,
        }
      ];
    }

    if (weddingInfo?.upgrade) {
      ({ data, success } = await GetUpgradeDetail(weddingInfo?.upgrade._id ? weddingInfo?.upgrade._id : weddingInfo?.upgrade));

      if (success) {
        upgrade = data;
      }
    }

    this.setState({
      payments,
      expenses,
      expensesExtras,
      numTables,
      numGuests,
      minGuests,
      numPlaces,
      numChildren,
      numStaff,
      numPax,
      numPaxShow,
      music,
      priceGuest,
      paymentsList,
      expensesList,
      offersList,
      receiptsList,
      discountsList,
      total,
      offer,
      discount,
      upgrade,
      paymentPhases,
      ready: true,
    });
  };

  renderPrice = (price) => {
    if (price !== undefined) price = parseFloat(price);
    return `${numberFormat(price || 0, 2, ",", ".")}€`;
  };

  showConfirmedInfo = (onlyConfirmed = false) => {
    const { weddingInfo } = this.props;
    if (onlyConfirmed) {
      return (weddingInfo?.status === WeddingStatus.CONFIRMED);
    }
    return (weddingInfo?.status === WeddingStatus.RESERVE_SINALIZATION || weddingInfo?.status === WeddingStatus.CONFIRMED);
  };

  render() {
    const { activeLanguage, translate, weddingInfo } = this.props;
    const {
      payments,
      expenses,
      expensesExtras,
      numTables,
      numGuests,
      minGuests,
      numPlaces,
      music,
      numChildren,
      numStaff,
      numPax,
      numPaxShow,
      priceGuest,
      paymentsList,
      expensesList,
      offersList,
      receiptsList,
      discountsList,
      total,
      upgrade,
      ready,
    } = this.state;
    const { iban, swift } = this.state;
    const { paymentPhases } = this.state;
    const { receiptModalVisible, currentReceipt, receiptOnlyView } = this.state;
    const { isProcessClosed } = this.state;    

    if (!ready) return <SpinLoading />;

    return (
      <BudgetContainer>
        <Title>{translate("ESTIMATED_BUDGET")}</Title>
        <Description>{translate("BUDGET_TEXT")}</Description>
        <BudgetTotal>
          {`${translate("TOTAL_VALUE")}: ${numberFormat(total, 2, ",", ".")}€`}
        </BudgetTotal>
        <BudgetGraphsContainer>
          <div className="graph1">
            <BudgetGraph title={translate("PAYMENTS")} data={payments} />
            <BudgetInfo>
              <ul>
                {this.showConfirmedInfo() && weddingInfo?.updatedBudget &&
                  <li className='updated line'>
                    <div className="title">{translate('UPDATED_ON')}:</div>
                    <span>{moment.utc(weddingInfo?.updatedBudget).format('DD/MM/YYYY HH:mm')}</span>
                  </li>}
                {iban && paymentsList?.length > 0 &&
                  <React.Fragment>
                    <li>
                      <Subtitle payment={true}>{translate('PAYMENT_DATA')}</Subtitle>
                    </li>
                    <li className='iban line'>
                      <div>{translate('IBAN')}:</div>
                      <span>{iban} {swift || ''}</span>
                    </li>
                  </React.Fragment>
                }
                {this.showConfirmedInfo() && Array.isArray(paymentPhases) && paymentPhases.length > 0
                  && <React.Fragment>
                    <li>
                      <Subtitle payment={true}>{translate('PAYMENT_STAGES')}</Subtitle>
                    </li>
                    {paymentPhases.map((p, index) => {
                      return <li key={'phase' + index} className='payment-phase line'>
                        <div className='title'>
                          <div>{p?.phase?.pt || ''} {p?.order || ''}: </div>
                          <span>{getPhaseDate(p, translate)}</span>
                        </div>
                        {p?.order > 2 && p?.paymentQuestion && <div className='subtitle'>
                          {p?.paymentQuestion?.option?.title && <span>
                            <Icon type='question-circle' />
                            {p?.paymentQuestion?.option?.title?.pt || ''}
                          </span>
                          }
                        </div>}
                        <div className='price'>
                          {p?.order == 3 && <span className='preview'>
                            <b>{translate('ESTIMATED_FROM')}:</b>
                            <span>{this.renderPrice(p?.toPay || 0)}</span>
                            <b>{translate('ESTIMATED_TO')}</b>
                            <span>{this.renderPrice(p?.totalMissing || 0)}</span>
                          </span>}
                          {p?.order == 4 && paymentPhases.length > 4 && <span className='preview'>
                            <b>{translate('FORESEEN')}:</b>
                            <span>{this.renderPrice(p?.toPay || 0)}</span>
                            <b>{translate('ESTIMATED_TO')}</b>
                            <span>{this.renderPrice(p?.totalMissing || 0)}</span>
                          </span>}
                          {p?.order == 4 && paymentPhases.length == 4 && <span className='preview'>
                            <b>{translate('FORESEEN')}:</b>
                            <span>{this.renderPrice(p?.toPay || 0)}</span>
                          </span>}
                          {p?.order > 4 && <span className='preview'>
                            <b>{translate('FORESEEN')}:</b>
                            <span>{this.renderPrice(p?.toPay || 0)}</span>
                          </span>}
                          <span className='preview'><b>{translate('PAID')}:</b><span>{this.renderPrice(p?.payed || 0)}</span></span>
                          {p?.order == 3 && checkPreviousPhasesCompleted(p, paymentPhases) && <span style={{ color: getMissingValue(p) > 0 ? 'red' : 'inherit' }}>
                            <b>{translate('MISSING')}:</b>
                            <span>{this.renderPrice(getMissingValue(p))}</span>
                          </span>}
                          {p?.order == 4 && paymentPhases.length > 4 && checkPreviousPhasesCompleted(p, paymentPhases) && <span style={{ color: getMissingValue(p) > 0 ? 'red' : 'inherit' }}>
                            <b>{translate('MISSING')}:</b>
                            <span>{this.renderPrice(getMissingValue(p))}</span>
                          </span>}
                        </div>
                      </li>
                    })}
                  </React.Fragment>}
              </ul>
            </BudgetInfo>
          </div>
          <BudgetGraph title={translate("EXPENSES")} data={expenses} />
        </BudgetGraphsContainer>
        <Subtitle>{translate("PAYMENTS_MADE")}</Subtitle>
        <BudgetTablePayments payments={payments} />
        {this.showConfirmedInfo() && <React.Fragment>
          <BudgetTableExpenses
            expenses={expenses}
            expensesExtras={expensesExtras}
            expensesList={expensesList}
            offersList={offersList}
            receiptsList={receiptsList}
            discountsList={discountsList}
            numTables={numTables}
            numGuests={numGuests}
            minGuests={minGuests}
            numPlaces={numPlaces}
            numChildren={numChildren}
            numStaff={numStaff}
            numPax={numPax}
            numPaxShow={numPaxShow}
            music={music}
            priceGuest={priceGuest}
            upgrade={upgrade}
            weddingInfo={weddingInfo}
          />
        </React.Fragment>
        }
        {/* Payments */}
        <SectionTitleContainer>
          <SectionIcon src={Hand} />
          <SectionTitle>{translate("PAYMENTS_MADE")}</SectionTitle>
        </SectionTitleContainer>
        <SectionSubtitle>{translate("PAYMENTS_TEXT")}</SectionSubtitle>
        {paymentsList.length > 0 ? (
          paymentsList.map((payment, index) => (
            <BudgetSection
              key={index}
              title={payment.title}
              section=""
              text={payment.description}
              amount={`-${numberFormat(payment.value, 2, ",", ".")}€`}
              date={`${translate("DATE")}: ${moment(payment.date)
                .utc()
                .format("DD/MM/YYYY")}`}
            />
          ))
        ) : (
          <NoRecordsContainer>
            <NoRecords>{translate("BUDGET_NO_PAYMENTS")}</NoRecords>
          </NoRecordsContainer>
        )}

        {this.showConfirmedInfo(true) && <React.Fragment>
          {/* Discounts */}
          <SectionTitleContainer>
            <SectionIcon src={Hand} />
            <SectionTitle>{translate("DISCOUNTS_TITLE")}</SectionTitle>
          </SectionTitleContainer>
          <SectionSubtitle>{translate("DISCOUNTS_TEXT")}</SectionSubtitle>
          {discountsList.length > 0 && (
            discountsList.map((discount, index) => (
              <BudgetSection
                key={index}
                title={discount.title}
                section=""
                text={discount.description}
                amount={`-${numberFormat(discount.value, 2, ",", ".")}€`}
                date={`${translate("DATE")}: ${moment(discount.date)
                  .utc()
                  .format("DD/MM/YYYY")}`}
              />
            ))
          )}

          {/* Expenses */}
          <SectionTitleContainer>
            <SectionIcon src={Money} />
            <SectionTitle>{translate("EXPENSES_TITLE")}</SectionTitle>
          </SectionTitleContainer>
          <SectionSubtitle>{translate("EXPENSES_TEXT")}</SectionSubtitle>
          {expensesList.length > 0 ? (
            expensesList.map((expense, index) => (
              <BudgetSection
                key={index}
                title={expense.title}
                section=""
                text={expense.description}
                amount={`+${numberFormat(expense.value, 2, ",", ".")}€`}
                date={`${translate("DATE")}: ${moment(expense.date)
                  .utc()
                  .format("DD/MM/YYYY")}`}
              />
            ))
          ) : (
            <NoRecordsContainer>
              <NoRecords>{translate("BUDGET_NO_EXPENSES")}</NoRecords>
            </NoRecordsContainer>
          )}

          {/* OFFERS */}
          <SectionTitleContainer>
            <SectionIcon src={Hand} />
            <SectionTitle>{translate("OFFERS_TITLE")}</SectionTitle>
          </SectionTitleContainer>
          <SectionSubtitle>{translate("OFFERS_TEXT")}</SectionSubtitle>
          {offersList.length > 0 && (
            offersList.map((offer, index) => (
              <BudgetSection
                key={index}
                title={offer.title}
                section=""
                text={offer.description}
                amount={`-${numberFormat(offer.value, 2, ",", ".")}€`}
                date={`${translate("DATE")}: ${moment(offer.date)
                  .utc()
                  .format("DD/MM/YYYY")}`}
              />
            ))
          )}
        </React.Fragment>}

        {/* RECEIPTS */}
        <SectionTitleContainer>
          <SectionIcon src={Hand} />
          <SectionTitle>{translate("PAYMENT_PROOF_TITLE")}</SectionTitle>
        </SectionTitleContainer>
        <SectionSubtitle>{translate("PAYMENT_PROOF_TEXT")}</SectionSubtitle>
        {receiptsList.length > 0 && (
          receiptsList.map((offer, index) => (
            <BudgetSection
              type={offer.type}
              document={offer.document}
              billing={offer.billing}
              key={index}
              title={offer.title}
              section=""
              text={offer.description}
              amount={`-${numberFormat(offer.value, 2, ",", ".")}€`}
              date={`${translate("DATE")}: ${moment(offer.date)
                .utc()
                .format("DD/MM/YYYY")}`}
              translate={translate}
              onDelete={() => this.deleteReceipt(offer?._id)}
              onOpenView={() => this.openViewReceiptModal(offer)}
            />
          ))
        )}

        {/* Iban */}
        {paymentsList?.length > 0 && <React.Fragment>
          <SectionTitleContainer>
            <SectionIcon src={CreditCard} />
            <SectionTitle>{translate("IBAN")}</SectionTitle>
          </SectionTitleContainer>
          <SectionSubtitle>{iban || ''} {swift || ''}</SectionSubtitle>
        </React.Fragment>}

        {weddingInfo.status !== 'R' && <SaveButton
          htmlType="submit"
          text={translate("ADD_PAYMENT_PROOF")}
          textMobile={translate("PAYMENT_PROOF")}
          onClick={() => this.openAddReceiptModal()}
        />}

        {receiptModalVisible && <PaymentProofModal open={receiptModalVisible}
          activeLanguage={activeLanguage}
          wedding={weddingInfo}
          type={'PAYMENT_PROOF'}
          closeModal={() => this.closeReceiptModal()}
          onSubmit={(data) => this.addReceipt(data)}
          translate={translate}
          initialValues={currentReceipt}
          onlyView={receiptOnlyView}
          isProcessClosed={isProcessClosed} />}
      </BudgetContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  weddingInfo: state.wedding.wedding,
  configs: state.info.configs,
  info: state.info
});

// const mapActionToProps = (dispatch) =>
//   bindActionCreators({ SaveWedding }, dispatch);

const mapActionToProps = (dispatch) => ({
  dispatch,
  updateAboutUsAction: (data) => dispatch(updateAboutUsAction(data)),
})

export default withLocalize(connect(mapStateToProps, mapActionToProps)(Budget));

import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import {withLocalize} from 'react-localize-redux';
import {
  MenuContainer,
  MenuFixed,
  Icon,
  Text,
  Menu,
  MenuRow
} from "./DecorationMenuStyles";
import ArrowLeft from '../../components/svg/ArrowLeft';
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";

const DecorationMenu = ({ baseUrl, questions, history, translate, activeLanguage }) => {
  const [decorationMenuOpen, setDecorationMenuOpen] = useState(false);

  const toggleDecorationMenu = () => {
    //If the Menu is open, means we're about to close so we restore the Body scroll
    if(decorationMenuOpen) {
      document.body.style.overflowY = 'auto';
    }
    //Opening the Menu, hide the Body scroll
    else {
      document.body.style.overflowY = 'hidden';
    }

    setDecorationMenuOpen(!decorationMenuOpen);
  }

  const closeDecorationMenu = () => {
    setDecorationMenuOpen(false);
    document.body.style.overflowY = 'auto';
  }
  
  return (
    <OutsideAlerter close={closeDecorationMenu}>
      <MenuContainer open={decorationMenuOpen}>
        <MenuFixed onClick={() => toggleDecorationMenu()}>
          <Icon open={decorationMenuOpen}><ArrowLeft/></Icon>
          <Text>{translate("ALL_CATEGORIES")}</Text>
        </MenuFixed>
        <Menu>
          {
            questions?.map((elem, index) => 
              <MenuRow key={index} borderTop={index === 0 ? false: true} onClick={() => history.push(`${baseUrl}/${elem._id}`)}>{elem.title[activeLanguage?.code]}</MenuRow>
            )
          }
        </Menu>
      </MenuContainer>
    </OutsideAlerter>
  );
}

export default withLocalize(withRouter(DecorationMenu));